export enum USER_STATUS {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  PENDING = "Awaiting approval",
}

export enum   USER_STATUS_VALUE {
  ACTIVE = 0,
  INACTIVE = 1,
  PENDING = 2,
}
