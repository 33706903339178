import { PAYOUT_STATUS, PAYOUT_STATUS_VALUE, WORKFLOW_STATUS, WORKFLOW_STATUS_VALUE } from "../config/enum/common.enum";
import { USER_STATUS, USER_STATUS_VALUE } from "../config/enum/userStatus.enum";

export const getStatusStyleName = (status: number, label?: string) => {
  switch (status) {
    case USER_STATUS_VALUE.ACTIVE:
      return {
        styles: "w-[7px] h-[7px] bg-[#27A551] rounded-[50%]",
        label: label ? label : USER_STATUS.ACTIVE,
      };
    case USER_STATUS_VALUE.INACTIVE:
      return {
        styles: "w-[7px] h-[7px] bg-[#FF0000] rounded-[50%]",
        label: label ? label : USER_STATUS.INACTIVE,
      };
    case USER_STATUS_VALUE.PENDING:
      return {
        styles: "w-[7px] h-[7px] bg-[#FFA500] rounded-[50%]",
        label: label ? label : USER_STATUS.PENDING,
      };
    default:
      return {
        styles: "w-[7px] h-[7px] bg-[#FFA500] rounded-[50%]",
        label: label ? label : USER_STATUS.PENDING,
      };
  }
};


export const getStatusStyle = (status: number, label?: string) => {
  switch (status) {
    case WORKFLOW_STATUS_VALUE.PENDING:
    case PAYOUT_STATUS_VALUE.PENDING:
      return {
        styles: "w-[7px] h-[7px] bg-[#FFA500] rounded-[50%]",
        label: label ? label : WORKFLOW_STATUS.PENDING,
      };
    case WORKFLOW_STATUS_VALUE.DECLINED:
    case PAYOUT_STATUS_VALUE.FAILED:
      return {
        styles: "w-[7px] h-[7px] bg-[#FF0000] rounded-[50%]",
        label: label ? label : WORKFLOW_STATUS.DECLINED,
      };
    case WORKFLOW_STATUS_VALUE.APPROVE:
      return {
        styles: "w-[7px] h-[7px] bg-[#27A551] rounded-[50%]",
        label: label ? label : WORKFLOW_STATUS.APPROVE,
      };
    default:
      return {
        styles: "w-[7px] h-[7px] bg-[#FFA500] rounded-[50%]",
        label: label ? label : WORKFLOW_STATUS.PENDING,
      };
  }
};