import { ReactNode } from "react";
import "./DetailRow.scss"

interface DetailRowType {
  title: string;
  value: ReactNode | string;
}

const DetailRow = ({ title, value }: DetailRowType) => {
  const separateCapitalLetters = (input: string): string => {
    // Replace sequences of two or more capital letters with a space between them
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  };
  
  return (
    <div className="detail-row">
      <p className="row-label text-nowrap">{title ? separateCapitalLetters(title) : ""}</p>
      <p className="row-content text-ellipsis line-clamp-1">{value}</p>
    </div>
  );
};

export default DetailRow;
