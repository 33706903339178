export const downloadFile = (fileUrl: string, fileName: any) => {
    // get file extension
    let fileExtension = fileUrl.split('.').pop()

    let xhr = new XMLHttpRequest()
    xhr.open('GET', fileUrl)
    xhr.responseType = 'blob'

    xhr.onload = () => {
        saveFile(xhr.response, fileExtension, fileName)
    }
    xhr.send()
}

const saveFile = (
    blob: Blob | MediaSource,
    fileExtension: string | undefined,
    fileName: any
) => {
    let anchor = document.createElement('a')
    document.body.appendChild(anchor)
    anchor.style.setProperty('display', 'none')

    let url = window.URL.createObjectURL(blob)
    anchor.href = url
    anchor.download = `${fileName}.${fileExtension}`
    anchor.click()
    window.URL.revokeObjectURL(url)
}
