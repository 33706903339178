import { totalTaxInfo } from "../../../utils/helper";
import { chargeDataType } from "../../PayoutCreationDrawer/types";

interface TaxBreakDownTextType {
  handleModalPopup: () => void;
  taxChargeList: chargeDataType[];
}

const TaxBreakDownText = ({
  handleModalPopup,
  taxChargeList,
}: TaxBreakDownTextType) => {
  return (
    <div
      onClick={handleModalPopup}
      className="w-fit rounded-md bg-[#ECECEC] hover:bg-[#efefef] font-Archivo font-medium text-xs px-2 py-1 !text-[#0D0D0D99] cursor-pointer transition-all duration-200">
      {totalTaxInfo(taxChargeList).totalPercentage}% tax
    </div>
  );
};

export default TaxBreakDownText;
