import "./CustomMobileDateRange.scss";
import { useRef, useState } from "react";
import { DateRangePicker } from "rsuite";

interface CustomMobileDateRangeType {
  setDateValue: (range: Date[]) => void;
}

const CustomMobileDateRange = ({ setDateValue }: CustomMobileDateRangeType) => {
  const dateRangeRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (range: Date[]) => {
    if (range){
      setDateValue(range);
    }
  };

  return (
    <div className="custom-daterange-bottomsheet">
      <div className="embedded-date-range-picker" ref={dateRangeRef}>
        <DateRangePicker
          showOneCalendar
          ranges={[]}
          onOk={handleDateChange}
          format="MM/dd/yyyy"
          character="-"
          open
          preventOverflow
          block
          placement="top"
          weekStart={1}
          cleanable={false}
          container={() => dateRangeRef.current as HTMLElement}
        />
      </div>
    </div>
  );
};

export default CustomMobileDateRange;
