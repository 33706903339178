import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSavedState } from "../utils/localStorage";

interface ProtectedRouteProps {
  children: ReactNode;
  requiredPermissions: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredPermissions }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getSavedState("NSIBUser");
    if (!auth) {
      navigate("/login");
    }

    const userPermissions = auth?.permission || [];
    const hasAccess =
      requiredPermissions.length === 0 ||
      requiredPermissions.every((permission) => userPermissions.includes(permission));

    if (!hasAccess) {
      navigate("/login");
    }
  }, [navigate, requiredPermissions]);

  return <>{children}</>;
};

export default ProtectedRoute;
