import React, { createContext, useContext, useState, ReactNode } from 'react';

interface NotificationContextType {
  shouldRefetch: boolean;
  setShouldRefetch: (value: boolean) => void;
}

// Create a context with a default value
export const NotificationContext = createContext<NotificationContextType>({
  shouldRefetch: false,
  setShouldRefetch: () => {},
});

// Create a provider component
export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [shouldRefetch, setShouldRefetch] = useState(false);

  return (
    <NotificationContext.Provider value={{ shouldRefetch, setShouldRefetch }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the NotificationContext
export const useNotification = () => useContext(NotificationContext);
