import { Modal } from "antd";
import OutlineButton from "../OutlineButton/OutlineButton";
import { BulkPayoutPopupRowData, TableBulkPayoutPopupData } from "./types";
import { ReactComponent as DocumentIcon } from "../../assets/icons/documenticon2.svg";
import Table from "../Table/Table";
import RenderIntialsFromVendorName from "../RenderIntialsFromVendorName/RenderIntialsFromVendorName";
import { capitalizeChargeKeys, formatCurrency } from "../../utils/helper";
import TaxBreakDownText from "../PayoutTable/TaxBreakDownText/TaxBreakDownText";
import { useState } from "react";
import SupportDocumentModal from "../SupportDocumentModal/SupportDocumentModal";
import RenderBankWithIcon from "../PayoutDetailDrawer/RenderBankWithIcon/RenderBankWithIcon";
import TaxBreakDownModal from "../PayoutTable/TaxBreakDownModal/TaxBreakDownModal";

interface BulkPayoutDetailBreakdownPopupType {
  showPopup: boolean;
  setShowPopup: (showPopup: boolean) => void;
  payeeList?: BulkPayoutPopupRowData[] | [] | null;
  isFromRequestModule?: boolean;
}

const BulkPayoutDetailBreakdownPopup = ({
  showPopup,
  setShowPopup,
  payeeList,
  isFromRequestModule = false,
}: BulkPayoutDetailBreakdownPopupType) => {
  const [showTaxModal, setShowTaxModal] = useState(false);

  const handleShowTaxBreakDownModal = () => {
    setShowTaxModal(true);
  };
  const [showSupportingDocumentModal, setShowSupportingDocumentModal] =
    useState(false);
  const [selectedDataToShow, setSelectedDataToShow] =
    useState<BulkPayoutPopupRowData | null>(null);

  // The data type differs so in this case handle both versions and assign em
  const transformedPayeeList = isFromRequestModule
    ? payeeList?.map((item) => {
        const transformedItem = { ...item };

        // Apply JSON.parse to the "Charges" field if it exists and is a valid string
        if (
          transformedItem.Charges &&
          typeof transformedItem.Charges === "string"
        ) {
          transformedItem.Charges = JSON.parse(transformedItem.Charges) || [];
        }

        return transformedItem;
      })
    : payeeList;
    
  const tableHead: {
    name: keyof TableBulkPayoutPopupData;
    displayName: string;
  }[] = isFromRequestModule
    ? [
        { name: "VendorName", displayName: "Name" },
        { name: "Amount", displayName: "Amount" },
        { name: "TaxPayable", displayName: "Tax payable" },
        { name: "PayableAmount", displayName: "Amount payable" },
        { name: "BankName", displayName: "Bank name" },
        { name: "AccountName", displayName: "Account name" },
        { name: "AccountNumber", displayName: "Account number" },
        { name: "Reason", displayName: "Reason" },
        { name: "DocumentLink", displayName: "Support document" },
      ]
    : [
        { name: "vendorName", displayName: "Name" },
        { name: "amount", displayName: "Amount" },
        { name: "taxPayable", displayName: "Tax payable" },
        { name: "payableAmount", displayName: "Amount payable" },
        { name: "bankName", displayName: "Bank name" },
        { name: "accountName", displayName: "Account name" },
        { name: "accountNumber", displayName: "Account number" },
        { name: "reason", displayName: "Reason" },
        { name: "documentLink", displayName: "Support document" },
      ];

  return (
    <Modal
      title={<p className="text-2xl font-semibold">Vendor details</p>}
      open={showPopup}
      onCancel={() => {
        setShowPopup(false);
        // setActiveRow(null);
      }}
      width={"80%"}
      onOk={() => setShowPopup(false)}
      footer={null}>
      <div className="main-table-wrapper my-10">
        <Table<TableBulkPayoutPopupData, BulkPayoutPopupRowData>
          fields={tableHead}
          tableData={transformedPayeeList || []}
          builder={(field, data) => {
            switch (field.name) {
              // Handle how table data will show for other places
              case "vendorName":
                return (
                  <RenderIntialsFromVendorName
                    vendor_name={data.vendorName || ""}
                  />
                );
              case "amount":
                return <span>{formatCurrency(data?.amount)}</span>;
              case "taxPayable":
                return (
                  <div className="flex items-center gap-1.5">
                    {formatCurrency(data?.taxPayable)}
                    {data.taxPayable !== 0 && (
                      <span
                        className="h-fit w-fit"
                        onClick={() => {
                          setSelectedDataToShow(data);
                          setShowTaxModal(true);
                        }}>
                        <TaxBreakDownText
                          handleModalPopup={handleShowTaxBreakDownModal}
                          taxChargeList={
                            capitalizeChargeKeys(data?.charges) || []
                          }
                        />
                      </span>
                    )}
                  </div>
                );
              case "payableAmount":
                return (
                  <div>
                    {formatCurrency(data.amount - data.taxPayable || 0)}
                  </div>
                );
              case "bankName":
                return (
                  <span>
                    <RenderBankWithIcon bankName={data?.bankName} />
                  </span>
                );
              case "accountName":
                return <span>{data?.accountName}</span>;
              case "accountNumber":
                return <span>{data?.accountNumber}</span>;
              case "reason":
                return (
                  <span className="block text-wrap w-[140px]">
                    {data?.reason}
                  </span>
                );
              case "documentLink":
                return data?.documentLink === null ||
                  data?.documentLink === "" ? (
                  <span className="w-full flex items-center justify-center">
                    ---
                  </span>
                ) : (
                  <OutlineButton
                    onClick={() => {
                      setShowSupportingDocumentModal(true);
                      setSelectedDataToShow(data);
                    }}
                    icon={<DocumentIcon />}
                    label={"Click to view"}
                    className="font-medium !py-1.5 text-nowrap !text-sm "
                  />
                );

              // For how table data will show for Request Module only
              case "VendorName":
                return (
                  <RenderIntialsFromVendorName
                    vendor_name={data.VendorName || ""}
                  />
                );
              case "Amount":
                return <span>{formatCurrency(data?.Amount)}</span>;
              case "TaxPayable":
                return (
                  <div className="flex items-center gap-1.5">
                    {formatCurrency(data?.TaxPayable)}
                    {data.TaxPayable !== 0 && (
                      <span
                        className="h-fit w-fit"
                        onClick={() => {
                          setSelectedDataToShow(data);
                          setShowTaxModal(true);
                        }}>
                        <TaxBreakDownText
                          handleModalPopup={handleShowTaxBreakDownModal}
                          taxChargeList={data?.Charges || []}
                        />
                      </span>
                    )}
                  </div>
                );
              case "PayableAmount":
                return (
                  <div>
                    {formatCurrency(data.Amount - data.TaxPayable || 0)}
                  </div>
                );
              case "BankName":
                return (
                  <span>
                    <RenderBankWithIcon bankName={data?.BankName} />
                  </span>
                );
              case "AccountName":
                return <span>{data?.AccountName}</span>;
              case "AccountNumber":
                return <span>{data?.AccountNumber}</span>;
              case "Reason":
                return (
                  <span className="block text-wrap w-[140px]">
                    {data?.Reason}
                  </span>
                );
              case "DocumentLink":
                return data?.DocumentLink === null ||
                  data?.DocumentLink === "" ? (
                  <span className="w-full flex items-center justify-center">
                    ---
                  </span>
                ) : (
                  <OutlineButton
                    onClick={() => {
                      setShowSupportingDocumentModal(true);
                      setSelectedDataToShow(data);
                    }}
                    icon={<DocumentIcon />}
                    label={"Click to view"}
                    className="font-medium !py-1.5 text-nowrap !text-sm "
                  />
                );

              default:
                return data[field?.name];
            }
          }}
        />
      </div>
      {selectedDataToShow && setShowSupportingDocumentModal && (
        <SupportDocumentModal
          showSupportingDocumentModal={showSupportingDocumentModal}
          setShowSupportingDocumentModal={setShowSupportingDocumentModal}
          documentLink={
            isFromRequestModule
              ? selectedDataToShow?.DocumentLink || ""
              : selectedDataToShow?.documentLink || ""
          }
        />
      )}
      {selectedDataToShow && showTaxModal && (
        <TaxBreakDownModal
          amountPaid={
            isFromRequestModule
              ? selectedDataToShow?.Amount
              : selectedDataToShow?.amount
          }
          amountTaxable={
            isFromRequestModule
              ? selectedDataToShow?.TaxPayable
              : selectedDataToShow?.taxPayable
          }
          taxChargeList={
            isFromRequestModule
              ? selectedDataToShow?.Charges || []
              : capitalizeChargeKeys(selectedDataToShow?.charges) || []
          }
          showTaxBreakDownModal={showTaxModal}
          setShowTaxBreakDownModal={setShowTaxModal}
        />
      )}
    </Modal>
  );
};

export default BulkPayoutDetailBreakdownPopup;
