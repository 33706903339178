import "./FileUpload.scss";
import React, { ChangeEvent, useRef } from "react";
import OutlineButton from "../OutlineButton/OutlineButton";
import FileInfo from "./FileInfo/FileInfo";
import { toast } from "react-toastify";

interface UploadedFileType {
  file: File;
  name: string;
  size: number;
  type: string;
  base64Format: string;
}

interface FileUploadType {
  supportingDocument: UploadedFileType[];
  setSupportingDocument: (supportingDocument: UploadedFileType[]) => void;
  maxFileSize?: number;
  allowFileType?: string;
  allowOnlyOneFile?: boolean
  customButtonLabel?: string
  showToast?: boolean
}

const FileUpload = ({
  supportingDocument,
  setSupportingDocument,
  maxFileSize=2,
  allowFileType=".jpg,.jpeg,.png,.pdf",
  allowOnlyOneFile = false,
  customButtonLabel="Add Document",
  showToast=false
}: FileUploadType) => {
  const supportingDocumentInputRef = useRef<HTMLInputElement>(null);
  const MAX_FILE_SIZE_MB = maxFileSize;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (showToast){
      toast.success("File upload in progress");
    }
    const files = Array.from(e.target.files || []);
    const newFiles: UploadedFileType[] = [];

    files.forEach((file) => {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        // alert(`File ${file.name} exceeds the 2MB size limit.`);
        toast.error(`File size exceeds the 2MB limit.`);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
           
          // Using the base64 string as it is 
          const base64String = reader.result;   
                
          newFiles.push({
            file,
            name: file.name,
            size: file.size,
            type: file.type.startsWith("image/") ? "Image" : file.type.startsWith("text/") ? "CSV" : "PDF",
            base64Format: base64String, 
          });

          if (newFiles.length === files.length) {
            setSupportingDocument([...supportingDocument, ...newFiles]);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveFile = (index: number) => {
    setSupportingDocument(supportingDocument.filter((_, i) => i !== index));

    if (supportingDocumentInputRef.current) {
      supportingDocumentInputRef.current.value = '';
    }
  };

  const handleAddMoreFiles = () => {
    supportingDocumentInputRef.current?.click();
  };

  return (
    <div className="support-document-component">
      {!allowOnlyOneFile && <label className="label" htmlFor="supporting_document">
        Supporting document <span>(Optional)</span>
      </label>}
      <input
        ref={supportingDocumentInputRef}
        id="supportingDocument"
        type="file"
        multiple
        accept={allowFileType}
        className="supporting-document-input"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {supportingDocument.length === 0 ? (
        <div className="image-upload-block" onClick={handleAddMoreFiles}>
          <OutlineButton
            label={customButtonLabel}
            className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm"
          />
        </div>
      ) : (
        <div className="files-container">
          {supportingDocument.map((file, index) => (
            <FileInfo
              key={index}
              uploadedDocument={file}
              removeFile={handleRemoveFile}
              index={index}
            />
          ))}
          {!allowOnlyOneFile &&<OutlineButton
            label={"Add Document"}
            onClick={handleAddMoreFiles}
            className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm w-fit"
          />}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
