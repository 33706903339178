import PrimaryButton from "../Button/PrimaryButton";
import { CloseOutlined } from "@ant-design/icons";
import { ArrowLeft } from "solar-icon-set/arrows";
import { Dispatch } from "react";
import OutlineButton from "../OutlineButton/OutlineButton";
import "./StatusContainer.scss";

interface NoticeInfoType {
  status: string;
  setOpenDrawer: Dispatch<React.SetStateAction<boolean>>;
  heading: string;
  icon: React.ReactNode;
  description: string;
  closeFunction: () => void;
  buttonText: string;
  type?: string;
  showBackButton?: boolean;
  onRetry?: () => void;
  children?: React.ReactNode;
  CustomBackAction?: () => void;
  backTitle?: string;
}

const StatusContainer = ({
  status,
  heading,
  icon,
  description,
  closeFunction,
  setOpenDrawer,
  buttonText,
  showBackButton,
  children,
  onRetry,
  type = "modal",
  CustomBackAction,
  backTitle = "Back",
}: NoticeInfoType) => {
  return (
    <div className={`status-content-container ${status}`}>
      <div className="flex justify-end w-full p-5">
        {type === "modal" ? (
          <button className="cancel-button" onClick={closeFunction}>
            <CloseOutlined className="x-icon !hidden md:!block" />
            <ArrowLeft className="arrow-icon !block md:!hidden" />
          </button>
        ) : null}
      </div>

      <div className="check-mark">{icon}</div>
      <div className="text-container">
        <h2>{heading}</h2>
        <p>{description}</p>
      </div>
      {children ? (
        children
      ) : (
        <div className="flex justify-center gap-4">
          {showBackButton && (
            <OutlineButton
              className="font-medium px-3.5 !py-2 text-nowrap !text-sm  !w-[150px]"
              label={backTitle}
              onClick={() =>
                CustomBackAction ? CustomBackAction() : closeFunction()
              }
            />
          )}

          <PrimaryButton
            label={buttonText ? buttonText : "Done"}
            className="font-medium px-3.5 !py-2 text-nowrap !text-sm  !w-[150px]"
            onClick={() => (onRetry ? onRetry() : closeFunction())}
          />
        </div>
      )}
    </div>
  );
};

export default StatusContainer;
