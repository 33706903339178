import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/appRoutes";
import { ToastContainer } from 'react-toastify'
import { NotificationProvider } from "./state/provider/NotificationProvider";


function App() {
  return (
    <NotificationProvider>
    <BrowserRouter>
        <ToastContainer className="p-3 w-fit text-sm" />
      <AppRoutes />
    </BrowserRouter>
    </NotificationProvider>

   
  );
}

export default App;
