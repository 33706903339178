import { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchBanks } from '../services/vendor'


export default function useBanks() {
    const [banks, setBanks] = useState([])

    const payload = {
        pageSize: 100,
        from: 0,
        parameter: {},
    }

    const { refetch } = useQuery(
        ['GetAllBanks', payload],
        () => fetchBanks(payload),
        {
            enabled: true,
            retry: 2,
            onSuccess: (response) => {
                setBanks(response.data.data)
            },
        }
    )

    const getBanks = () => {
        refetch()
    }

    return { banks, getBanks }
}
