import { FC, ReactNode, useState } from "react";
import "./LandingLayout.scss";
import loginIcon from "../../assets/images/Login-Icon.svg";
import ArrowHead from "../../assets/images/arrow-head.svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import { motion } from "framer-motion";

const LandingLayout: FC<{
  children: ReactNode;
  contentContainerClass?: string;
  disableScroll?: boolean; // New prop

}> = ({ children, contentContainerClass, disableScroll = true }) => {

  return (
    <div className="landing-container overflow-hidden bg-none md:bg-[#fafafa]">
      <div className="flex flex-row relative h-screen">
        {/* Background Overlay with Black and Light Gradient */}
        <div className="lg:flex hidden w-[50%] h-full flex-col relative">
          <div className="absolute inset-0 z-20 bg-[#f3f0f0] opacity-50"></div>
          <div className="absolute inset-0 z-30 bg-gradient-to-r from-transparent to-[#F0F0F0] opacity-50"></div>

          {/* Animation Layer */}
          <motion.div
            animate={{ y: ["-0%", "20%"] }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "linear",
              repeatType: "loop",
            }}
            className="relative z-10 pl-[5%]"
          >
            <img src={ArrowDown} alt="Arrow Down" />
          </motion.div>

          <div className="h-full flex flex-row items-center z-40 relative">
            <div className="flex flex-row gap-4 w-auto rounded-lg p-4 pl-[5%] border-none"
            style={{
              background: "radial-gradient(circle, rgba(250, 250, 250, 0.4), transparent)",
            }}
            >
              <img src={loginIcon} alt="Login Icon" />
              <span className="text-[32px] font-semibold">
                Simplifying your <br /> payouts
              </span>
            </div>
          </div>

          <motion.div
            animate={{ y: ["0%", "-20%"] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: "linear",
              repeatType: "loop",
            }}
            className="relative z-10 pl-[5%]"
          >
            <img src={ArrowHead} alt="Arrow Up" />
          </motion.div>
        </div>

        {/* Content Container */}
        <div
          // className={`landing-border w-[100%] lg:max-w-[50%] h-auto bg-white py-4 md:py-[20px] lg:py-[30px] px-4 md:px-8 lg:px-[50px] overflow-y-scroll ${contentContainerClass}`}
          className={`landing-border w-[100%] lg:max-w-[50%] h-auto bg-white py-4 md:py-[20px] lg:py-[30px] px-4 md:px-8 lg:px-[50px] ${disableScroll ? '' : 'overflow-y-scroll'} ${contentContainerClass}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default LandingLayout;
