import React, { useState } from "react";
import PrimaryButton from "../../Button/PrimaryButton";
import OutlineButton from "../../OutlineButton/OutlineButton";
import { chargeDataType, TaxItemType } from "../../PayoutCreationDrawer/types";
import { formatCurrency } from "../../../utils/helper";
import { Modal } from "antd";
import { getTaxes } from "../../../services/payout-request";
import { useQuery } from "react-query";

interface TaxBreakDownModalType {
  showTaxBreakDownModal: boolean;
  setShowTaxBreakDownModal: (showTaxBreakDownModal: boolean) => void;
  amountPaid: number;
  amountTaxable: number;
  taxChargeList: chargeDataType[];
  isBulkPayout?: boolean;
}

const TaxBreakDownModal = ({
  amountPaid,
  amountTaxable,
  taxChargeList,
  showTaxBreakDownModal,
  setShowTaxBreakDownModal,
  isBulkPayout=false,
}: TaxBreakDownModalType) => {
  // Get TaxList
  const { isLoading: isRetrievingTaxList, data: incomingTaxList } = useQuery(
    [],
    () => getTaxes(0),
    {
      enabled: true,
      retry: false,
    }
  );

  return (
    <Modal
      title="Tax Breakdown"
      open={showTaxBreakDownModal}
      closeIcon={null}
      onCancel={() => {
        setShowTaxBreakDownModal(false);
      }}
      footer={() => (
        <div className="buttons-container flex justify-end pt-10">
          <PrimaryButton
            label={"Done"}
            onClick={() => setShowTaxBreakDownModal(false)}
            className={`!text-sm !py-1.5 !px-4 flex justify-center items-center`}
          />
        </div>
      )}>
      {!isRetrievingTaxList ? (
        <>
          <p className="tax-info amount-payable pt-5 !font-medium">
            <span>Total excluding tax</span>
            <span>{formatCurrency(amountPaid)}</span>
          </p>
          <div className="tax-breakdown">
            {incomingTaxList &&
              taxChargeList &&
              incomingTaxList?.data?.map((tax: TaxItemType) =>
                taxChargeList.map((taxCharge: chargeDataType) =>
                  tax?.chargeType == taxCharge?.ChargeType ? (
                    <p className="tax-info ">
                      <span>
                        {isBulkPayout
                          ? `${tax.chargeTypeName} on ${(
                              parseFloat(
                                taxCharge?.ChargePercentage.substring(
                                  0,
                                  taxCharge?.ChargePercentage?.length - 1
                                ).trim()
                              ) / parseFloat(tax?.chargePercentage) || 0
                            ).toFixed(0)} Payout(s)`
                          : `${tax.chargeTypeName.substring(
                              0,
                              tax?.chargeTypeName?.length - 1
                            )}
                        on ${formatCurrency(amountPaid)})`}
                      </span>{" "}
                      <span>
                        -{formatCurrency(taxCharge?.CalculatedCharge || 0)}
                      </span>
                    </p>
                  ) : null
                )
              )}
          </div>
          <p className="tax-info amount-payable">
            <span>Amount payable</span>{" "}
            <span>{formatCurrency(amountPaid - amountTaxable)}</span>
          </p>
        </>
      ) : (
        <p className="label h-10">Loading, please wait...</p>
      )}
    </Modal>
  );
};

export default TaxBreakDownModal;
