import { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchNotificationTypes, searchNotification } from '../services/notification'
import { getSavedState } from '../utils/localStorage';
import moment from 'moment';
import { NotificationContext } from '../state/provider/NotificationProvider';

export default function useNotificationFetch(enabled = true) {
    const [notifications, setNotifications] = useState([])
    const user = getSavedState("NSIBUser");
    const { shouldRefetch , setShouldRefetch } = useContext(NotificationContext);


    const payload = {
        PageSize: 100,
        UserId: user.userId,
        Page: 1,
        StartDate: moment().subtract(30, "days").format("yy-MM-DD"),
        EndDate: moment().format("yy-MM-DD"),
        isSeen: false
        
       
    }

    const { refetch } = useQuery(
        ['getAllNotifications', payload],
        () => searchNotification(payload),
        {
            enabled: true,
            retry: 2,
            onSuccess: (response) => {
                setNotifications(response)
                setShouldRefetch(false)
            },
        }
    )

    const getNotifications = () => {
        refetch()
    }

    return { notifications, getNotifications }
}
