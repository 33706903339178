import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Layout, Menu, Popover } from "antd";
import NsibLogo from "../../assets/images/nsib-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Bill } from "../../assets/icons/billicon.svg";
import { ReactComponent as UsersGroupRounded } from "../../assets/icons/vendoricon.svg";
import { ReactComponent as ArchiveDownMinimlistic } from "../../assets/icons/requesticon.svg";
import { ReactComponent as PapersMinimalistic } from "../../assets/icons/auditlog.svg";
import { ReactComponent as Bell } from "../../assets/icons/notificationsicon.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancelicon.svg";
import { ReactComponent as DropDownIcon } from "../../assets/icons/dropdownarrow2.svg";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import {
  ContentProps,
  DashboardNavLinkInformationChildType,
  DashboardNavLinkInformationType,
} from "./DashboardLayoutTypes";
import SidebarLogoutPopover from "../SidebarLogoutPopover/SidebarLogoutPopover";
import { getSavedState } from "../../utils/localStorage";
import { deleteLocalState } from "../../utils/localStorage";
import { getFullNameInitials } from "../../utils/getInitials";
import { useWindowWidth } from "@react-hook/window-size";
import useNotificationFetch from "../../hooks/useFetchNotification";
import "./DashboardLayout.scss";
import { NotificationContext } from "../../state/provider/NotificationProvider";

const ContentLayout: FC<ContentProps> = ({ children, headerChildren }) => {
  const currentTheme = "light";
  const { Content, Sider } = Layout;
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const screenSize = useWindowWidth();
  const [enabled, setEnabled] = useState(false);
  const { notifications, getNotifications } = useNotificationFetch(enabled);
  const { shouldRefetch } = useContext(NotificationContext);

  // Handle showing and hiding of sidebar on mobile
  const [showSidebar, setShowSideBar] = useState(
    screenSize > 768 ? false : true
  );
  const toggleSider = () => {
    setShowSideBar(!showSidebar);
  };

  // State for handling SidebarPopOver for users to log out
  const [open, setOpen] = useState(false);

  // Function to hide the popover
  const hidePopover = () => {
    setOpen(false);
  };

  // Function to handle showing and hiding the popover
  const handlePopoverOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleLogout = () => {
    deleteLocalState("NSIBUser");
    window.location.href = "/login";
  };

  const user = getSavedState("NSIBUser");
  const fullName = user?.fullName || "";
  const firstName = fullName?.split(" ")[0] || "";
  const email = user?.userName || "";

  // Filter topNavLinks based on user permissions
  const filteredNavLinks = useMemo(() => {
    const userPermissions = user?.permission || [];
    const topNavLinks: DashboardNavLinkInformationType[] = [
      {
        key: "/home",
        icon: <Home />,
        label: "Home",
        permissions: [], // No permissions required
      },
      {
        key: "/payout",
        icon: <Bill />,
        label: "Payouts",
        permissions: ["Permission.Payout"],
        children: [
          {
            key: "/payouts",
            label: "Single payout",
            permissions: ["Permission.Payout"],
          },
          {
            key: "/bulk-payout",
            label: "Bulk payout",
            permissions: ["Permission.Payout"],
          },
        ],
      },
      {
        key: "/vendors",
        icon: <UsersGroupRounded />,
        label: "Payee",
        permissions: ["Permission.VendorInformation"],
      },
      {
        key: "/requests",
        icon: <ArchiveDownMinimlistic />,
        label: "Requests",
        permissions: ["Permission.Request"],
      },
      {
        key: "/audit-log",
        icon: <PapersMinimalistic />,
        label: "Audit Log",
        permissions: ["Permission.AuditLog"],
      },
      {
        key: "/notifications",
        icon: <Bell />,
        label: (
          <span className="notification">
            Notification
            {/* @ts-ignore */}
            {notifications?.totalCount > 0 && <span className="count-icon"> {notifications?.totalCount}</span>}
          </span>
        ),
        permissions: [], // No permissions required
      },
      {
        key: "/settings",
        icon: <Settings />,
        label: "Settings",
        permissions: ["Permission.Setting.User"],
      },
    ];

    return topNavLinks.filter(
      (link) =>
        link?.permissions?.length === 0 ||
        link?.permissions?.every((permission) =>
          userPermissions.includes(permission)
        )
    );
    // @ts-ignore
  }, [notifications?.totalCount, user?.permission]);

  useEffect(() => {
    if (shouldRefetch) {
      setEnabled(true);
      getNotifications();
    }
    getNotifications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  const [activeKey, setActiveKey] = useState<string>(pathname);

  const [showPayoutLinkDropDown, setShowPayoutLinkDropDown] = useState(false);

  const handleNavLinkChange = (key: string) => {
    if (key === "/payout") {
      setShowPayoutLinkDropDown(!showPayoutLinkDropDown);
    }
    else {
      setActiveKey(key);
      navigate(key);
    }
  };

  return (
    <Layout className="content-layout">
      {/* Side Bar component */}
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        theme={currentTheme}
        width={280}
        trigger={null}
        collapsible
        collapsed={showSidebar}
        onCollapse={setShowSideBar}
        className="custom-sider">
        {/* Sidebar Top components */}
        <div className="sidebar-top">
          <button className="sidebar-cancel-button" onClick={toggleSider}>
            <CancelIcon />
          </button>
          {/* NSIB Logo */}
          <img src={NsibLogo} alt="NSIB Logo" className="nsib-logo" />

          {/* First set of side bar Links */}
          {/* <Menu
            onClick={(currentLink: any) => {
              navigate(currentLink.key);
              console.log(currentLink);
              setActiveKey(currentLink.key);
            }}
            mode="inline"
            defaultSelectedKeys={[activeKey]}
            className="link-container"
            // items={topNavLinks}
            items={filteredNavLinks}
          /> */}
          <div className="space-y-3">
            {filteredNavLinks?.map(
              (link: DashboardNavLinkInformationType, id: number) => (
                <div key={id}>
                  <p
                    className={`sidebar-links ${
                      activeKey === link.key ? "active" : ""
                    }`}
                    onClick={() => {
                      handleNavLinkChange(link.key);
                    }}>
                    <span className="flex items-center w-full">
                      {link.icon}
                      {link.label}
                    </span>
                    {link.children && (
                      <span className="flex h-4 w-4 justify-end">
                        <DropDownIcon
                          className={`fill-[#1a1b1db2] ${
                            showPayoutLinkDropDown && "rotate-180"
                          }`}
                        />
                      </span>
                    )}
                  </p>
                  {link.children && (
                    <div
                      className={`transition-all duration-100 ease-in ${
                        showPayoutLinkDropDown
                          ? "animate-slide-down block mt-2"
                          : "hidden"
                      }`}>
                      {link.children.map(
                        (
                          child: DashboardNavLinkInformationChildType,
                          id: number
                        ) => (
                          <p
                            key={id}
                            className={`sidebar-links child ${
                              activeKey === child.key ? "active" : ""
                            }`}
                            onClick={() => {
                              handleNavLinkChange(child.key);
                            }}>
                            {child.label}
                          </p>
                        )
                      )}
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>

        {/* Sidebar bottom components */}
        <div className="sidebar-bottom">
          {/* Component containing users info */}
          <Popover
            content={<SidebarLogoutPopover hidePopover={handleLogout} />}
            trigger="click"
            className="popover"
            open={open}
            onOpenChange={handlePopoverOpenChange}>
            <div className="users-detail">
              <span className="icon-text">
                <p className="initials">{getFullNameInitials(fullName)}</p>
                <span className="others">
                  <p>{firstName}</p>
                  <p className="w-full line-clamp-1 text-ellipsis">{email}</p>
                </span>
              </span>
              <div>
                <LogoutIcon />
              </div>
            </div>
          </Popover>
        </div>
      </Sider>

      {/* Section for main page content */}
      <Layout className="page-container">
        <div
          className={`mobile-transparent-background ${
            !showSidebar ? "show md:!hidden" : "hide md:!hidden"
          } md:hidden`}
          onClick={() => {
            toggleSider();
          }}></div>
        {/* Component holding the page content including the header and the page content */}
        <Content className="content-container">
          {/* Dashbaord header component */}
          <DashboardHeader toggleSider={toggleSider}>
            {headerChildren}
          </DashboardHeader>

          {/* Main page component passed to the layer component */}
          <main className="">{children}</main>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ContentLayout;
