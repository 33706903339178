import { useLocation, useNavigate } from "react-router-dom";
import { BulkPayoutReviewRowData } from "../BulkPayoutReview/types";
import BulkPayoutLayout from "../../components/BulkPayoutLayout/BulkPayoutLayout";
import { formatCurrency } from "../../utils/helper";
import Table from "../../components/Table/Table";
import { getTaxes } from "../../services/payout-request";
import { useMutation, useQuery } from "react-query";
import {
  chargeDataPayloadType,
  TaxItemType,
} from "../../components/PayoutCreationDrawer/types";
import {
  FinalBulkPayoutReviewRowData,
  TableFinalBulkPayoutReviewData,
} from "./types";
import { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { initateBulkPayout } from "../../services/bulk-payout.service";
import { ReactComponent as CheckMarkIcon } from "../../assets/icons/checkmark.svg";
import { ReactComponent as GradientBackground } from "../../assets/icons/gradientbackground.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/Bill Cross.svg";
import { ReactComponent as ConnectionErrorIcon } from "../../assets/icons/connection-error.svg";
import StatusContainer from "../../components/StatusConatiner/StatusContainer";
import "./BulkPayoutSummary.scss";
import { toast } from "react-toastify";

const BulkPayoutSummary = () => {
  const navigate = useNavigate();
  const { payeeType, bulkPayoutReviewData } = useLocation().state || {};
  const [payload, setPayload] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const statuses = ["payoutSuccessful", "payoutFailed", "connectionError"];
  const [finalBulkPayoutData, setFinalBulkPayoutData] = useState<
    FinalBulkPayoutReviewRowData[]
  >([]);
  const [isStartingRowTaxFetching, setIsFetchingRowTaxFetching] =
    useState(true);

  // Get TaxList
  const { isLoading: isRetrievingTaxList, data: incomingTaxList } = useQuery(
    [],
    () => getTaxes(1000),
    {
      enabled: true,
      retry: false,
    }
  );

  // Function to retrieve tax data per row based on payableAmount
  const fetchTaxDataForRows = async () => {
    if (!bulkPayoutReviewData?.result?.dataItems) return [];

    // Use Promise.all to fetch tax data for each row
    const taxDataPromises = bulkPayoutReviewData.result.dataItems.map(
      async (item: BulkPayoutReviewRowData) => {
        try {
          const taxResponse = await getTaxes(item.payableAmount);
          return { ...item, taxList: taxResponse?.data || [] };
        } catch (error: any) {
          if (error?.response) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error("An error occurred, please try again later!");
          }
          return { ...item, taxList: [] };
        }
      }
    );

    // Wait for all tax data to be fetched
    const rowsWithTaxData = await Promise.all(taxDataPromises);
    return rowsWithTaxData;
  };

  const prepareFinalBulkPayoutData = async () => {
    const rowsWithTaxData = await fetchTaxDataForRows();

    const bulkPayoutData = rowsWithTaxData.map((row) => {
      let charges: chargeDataPayloadType[] = [];
      let totalTax = 0;

      // Helper function to add charge if applicable
      const addPreCalculatedCharge = (
        condition: boolean,
        chargeType: number
      ) => {
        if (condition) {
          const tax = row.taxList.find(
            (t: TaxItemType) => t.chargeType === chargeType
          );
          if (tax) {
            totalTax += tax.calculatedCharge;
            charges.push({
              chargeType: tax.chargeType,
              chargePercentage: tax.chargePercentage.toString(),
              calculatedCharge: tax.calculatedCharge,
            });
          }
        }
      };

      // Apply each tax condition based on row data
      addPreCalculatedCharge(row.vat, 1);
      addPreCalculatedCharge(row.whtGeneralContract, 2);
      addPreCalculatedCharge(row.whtConsultancyService, 3);
      addPreCalculatedCharge(row.stampDuty, 4);

      return {
        payeeId: row.payeeId,
        payeeName: row.payeeName,
        accountNumber: row.accountNumber,
        amount: parseFloat(row.payableAmount.toFixed(2)),
        reason: row.reason,
        documents: row.documentLinks ? [row.documentLinks] : [],
        taxPayable: parseFloat(totalTax.toFixed(2)),
        payableAmount: parseFloat((row.payableAmount - totalTax).toFixed(2)),
        charges,
      };
    });

    return bulkPayoutData;
  };

  // Fetch the bulk payout data when the component mounts
  useEffect(() => {
    const fetchBulkPayoutData = async () => {
      setIsFetchingRowTaxFetching(true);
      const data = await prepareFinalBulkPayoutData();
      setFinalBulkPayoutData(data);
      setIsFetchingRowTaxFetching(false);
    };

    fetchBulkPayoutData();
  }, []);

  const getTotalChargeByType = (chargeType: number) => {
    return finalBulkPayoutData?.reduce(
      (sum: number, item: FinalBulkPayoutReviewRowData) => {
        const chargeSum = item.charges
          .filter(
            (charge: chargeDataPayloadType) => charge.chargeType === chargeType
          )
          .reduce((acc, charge) => acc + charge.calculatedCharge, 0);
        return sum + chargeSum;
      },
      0
    );
  };

  // Calculate totals for each charge type
  const totalVat = isStartingRowTaxFetching ? 0 : getTotalChargeByType(1);
  const totalStampDuty = isStartingRowTaxFetching ? 0 : getTotalChargeByType(4);
  const totalWhtConsultancyService = isStartingRowTaxFetching
    ? 0
    : getTotalChargeByType(3);
  const totalWhtGeneralContract = isStartingRowTaxFetching
    ? 0
    : getTotalChargeByType(2);

  const tableHead: {
    name: keyof TableFinalBulkPayoutReviewData;
    displayName: string;
  }[] = [
    { name: "payeeName", displayName: "Payee name" },
    { name: "accountNumber", displayName: "Account number " },
    { name: "amount", displayName: "Amount" },
    ...(payeeType === "Vendor"
      ? ([
          { name: "vat", displayName: "VAT (7.5%)" },
          { name: "whtGeneralContract", displayName: "WHT (5%)" },
          { name: "whtConsultancyService", displayName: "WHT (10%)" },
          { name: "stampDuty", displayName: "Stamp duty (1%)" },
        ] as {
          name: keyof TableFinalBulkPayoutReviewData;
          displayName: string;
        }[])
      : []),
    { name: "reason", displayName: "Reason" },
    { name: "payableAmount", displayName: "Amount payable" },
  ];

  const { mutate: mutateAsync, isLoading: isSubmitingData } = useMutation(
    initateBulkPayout,
    {
      onSuccess: (response) => {
        setStatus("payoutSuccessful");
        setSuccessMessage(response.message);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        if (error?.response) {
          setStatus("payoutFailed");
          setErrorMessage(error?.response?.data?.message);
        }
      },
    }
  );

  const initateFinalBulkPayout = () => {
    const currentPayload = finalBulkPayoutData?.map(
      (item: FinalBulkPayoutReviewRowData) => ({
        payeeId: item.payeeId,
        amount: item.amount,
        reason: item.reason,
        documents: item.documents || [],
        taxPayable: item.taxPayable,
        charges: item.charges,
      })
    );
    setPayload({
      payeeType: payeeType === "Vendor" ? 1 : 2,
      payouts: currentPayload,
    });
    mutateAsync({
      payeeType: payeeType === "Vendor" ? 1 : 2,
      payouts: currentPayload,
    });
  };

  const renderIconAndStatus: any = useMemo(() => {
    switch (status) {
      case "payoutSuccessful":
        return {
          icon: (
            <>
              <CheckMarkIcon />
              <GradientBackground />
            </>
          ),
          status: "success",
          title: "Payout initiated",
          description:
            "An approver will be required to approve this action before the payout is completed",
        };
      case "connectionError":
        return {
          icon: <ConnectionErrorIcon />,
          status: "warning",
          title: "Connection Error",
          description:
            "Sorry, we lost connection. Your computer may be offline or we may be experiencing some problemsWe will automatically try to reconnect",
          showBackButton: true,
          actionText: "Retry",
          onRetry: () => {
            mutateAsync(payload);
          },
        };
      default:
        return {
          icon: <ErrorIcon />,
          status: "error",
          title: "Action Failed",
          description:
            errorMessage ||
            "An error occurred while trying to initiate this payout",
          showBackButton: true,
          actionText: "Retry",
          onRetry: () => {
            mutateAsync(payload);
          },
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, successMessage, payload, status]);

  return (
    <BulkPayoutLayout
      additionalClassName="bulk-summary-page"
      topCancelButtonAction={() => navigate("/bulk-payout")}
      bottomCancelButtonAction={() => navigate("/initiate-bulk-payout")}
      disableContinue={
        bulkPayoutReviewData?.result?.dataItems.filter(
          (item: BulkPayoutReviewRowData) => item.errorExists === true
        ).length > 0 ||
        isRetrievingTaxList ||
        isStartingRowTaxFetching
      }
      isContinueLoading={isSubmitingData}
      continueButtonAction={() => initateFinalBulkPayout()}
      showButtomButtons={status === ""}>
      {statuses.includes(status) ? (
        <StatusContainer
          heading={renderIconAndStatus?.title}
          buttonText={renderIconAndStatus?.actionText}
          description={renderIconAndStatus?.description}
          children={renderIconAndStatus?.children}
          status={renderIconAndStatus?.status}
          setOpenDrawer={() => navigate("/bulk-payout")}
          closeFunction={() => {
            navigate("/bulk-payout");
          }}
          CustomBackAction={() => setStatus("")}
          icon={renderIconAndStatus?.icon}
          showBackButton={renderIconAndStatus?.showBackButton}
          onRetry={renderIconAndStatus?.onRetry}
        />
      ) : (
        <>
          <div className="mt-[50px] ">
            <p className="text-2xl font-semibold text-[#0D0D0D]">
              Payout Summary
            </p>
          </div>
          <div className="mt-8 main-table-wrapper">
            <Table<TableFinalBulkPayoutReviewData, FinalBulkPayoutReviewRowData>
              fields={tableHead}
              tableData={finalBulkPayoutData || []}
              allowRelativeRow={true}
              rows={finalBulkPayoutData?.length || 0}
              isLoading={isStartingRowTaxFetching}
              builder={(field, data) => {
                switch (field.name) {
                  case "payeeName":
                    return (
                      <span className={`min-w-[300px] text-wrap`}>
                        {data?.payeeName}
                      </span>
                    );
                  case "accountNumber":
                    return <span>{data?.accountNumber}</span>;
                  case "amount":
                    return <div>{formatCurrency(data.amount)}</div>;
                  case "vat":
                    return payeeType === "Vendor" ? (
                      <span>
                        {formatCurrency(
                          data?.charges?.find(
                            (charge) => charge.chargeType === 1
                          )?.calculatedCharge || 0
                        )}
                      </span>
                    ) : null;
                  case "whtGeneralContract":
                    return payeeType === "Vendor" ? (
                      <span>
                        {formatCurrency(
                          data?.charges?.find(
                            (charge) => charge.chargeType === 2
                          )?.calculatedCharge || 0
                        )}
                      </span>
                    ) : null;
                  case "whtConsultancyService":
                    return payeeType === "Vendor" ? (
                      <span>
                        {formatCurrency(
                          data?.charges?.find(
                            (charge) => charge.chargeType === 3
                          )?.calculatedCharge || 0
                        )}
                      </span>
                    ) : null;
                  case "stampDuty":
                    return payeeType === "Vendor" ? (
                      <span>
                        {formatCurrency(
                          data?.charges?.find(
                            (charge) => charge.chargeType === 4
                          )?.calculatedCharge || 0
                        )}
                      </span>
                    ) : null;
                  case "reason":
                    return <span>{data?.reason || "---"}</span>;
                  case "payableAmount":
                    return (
                      <span className="">
                        {formatCurrency(data.payableAmount)}
                      </span>
                    );
                  default:
                    return data[field?.name];
                }
              }}
            />
          </div>
          {!isStartingRowTaxFetching && (
            <div className="w-full flex justify-end pt-10 pb-5 border-b">
              <div className="w-full md:w-[550px]">
                <p className="flex justify-between text-[#0D0D0D99] font-medium">
                  <span>Total excluding tax</span>
                  <span>
                    {formatCurrency(
                      !finalBulkPayoutData || isRetrievingTaxList
                        ? 0
                        : bulkPayoutReviewData?.result?.totalAmountPayable || 0
                    )}
                  </span>
                </p>
                {payeeType === "Vendor" &&
                  bulkPayoutReviewData?.result?.dataItems.filter(
                    (item: BulkPayoutReviewRowData) => item.vat === true
                  ).length > 0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>VAT (7.5%)</span>
                      <span>{formatCurrency(totalVat || 0)}</span>
                    </p>
                  )}
                {payeeType === "Vendor" &&
                  bulkPayoutReviewData?.result?.dataItems.filter(
                    (item: BulkPayoutReviewRowData) =>
                      item.whtGeneralContract === true
                  ).length > 0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>WTH (5%)</span>
                      <span>
                        {formatCurrency(totalWhtGeneralContract || 0)}
                      </span>
                    </p>
                  )}
                {payeeType === "Vendor" &&
                  bulkPayoutReviewData?.result?.dataItems.filter(
                    (item: BulkPayoutReviewRowData) =>
                      item.whtConsultancyService === true
                  ).length > 0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>WTH (10%)</span>
                      <span>
                        {formatCurrency(totalWhtConsultancyService || 0)}
                      </span>
                    </p>
                  )}
                {payeeType === "Vendor" &&
                  bulkPayoutReviewData?.result?.dataItems.filter(
                    (item: BulkPayoutReviewRowData) => item.stampDuty === true
                  ).length > 0 && (
                    <p className="flex justify-between text-[#0D0D0D99] font-medium text-sm">
                      <span>Stamp duty (1%)</span>
                      <span>{formatCurrency(totalStampDuty || 0)}</span>
                    </p>
                  )}
                <hr className=" border-dashed my-4" />
                <p className="flex justify-between text-[#0D0D0D] text-xl font-medium">
                  <span>Amount payable</span>
                  <span>
                    {formatCurrency(
                      isRetrievingTaxList || !finalBulkPayoutData
                        ? 0
                        : bulkPayoutReviewData?.result?.totalAmountPayable -
                            totalVat -
                            totalWhtGeneralContract -
                            totalWhtConsultancyService -
                            totalStampDuty || 0
                    )}
                  </span>
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </BulkPayoutLayout>
  );
};

export default BulkPayoutSummary;
