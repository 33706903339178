import React, { useState } from "react";
import ContentLayout from "../../components/DashboardLayout/DashboardLayout";
import { Popover } from "antd";
import OutlineButton from "../../components/OutlineButton/OutlineButton";
import { ReactComponent as DownloadIcon } from "../../assets/icons/downloadicon.svg";
import { ReactComponent as PayoutButtonIcon } from "../../assets/icons/payoutbuttonicon.svg";
import DownloadPopoverComponent from "../../components/DownloadPopoverComponent/DownloadPopoverComponent";
import moment from "moment";
import { useWindowWidth } from "@react-hook/window-size";
import { getSavedState } from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import { useMutation } from "react-query";
import { downloadFile } from "../../utils/downloadFile";
import { AxiosDefaults } from "axios";
import { toast } from "react-toastify";
import { downloadBulkPayout } from "../../services/payout-request";
import BottomSheet from "../../components/BottomSheet/BottomSheet";
import BulkPayoutTable from "../../components/BulkPayoutTable/BulkPayoutTable";
import { AxiosError } from "axios";

const BulkPayout = () => {
  const savedState = getSavedState("NSIBUser");
  const userPermissions = savedState?.permission || [];
  const screenSize = useWindowWidth();
  const navigate = useNavigate();
  const [showDownloadBottomSheet, setShowDownloadBottomSheet] = useState(false);
  const [openDownloadPopover, setOpenDownloadPopover] = useState(false);
  const [showPayee, setShowPayee] = useState(false);
  const [downloadFilterFilterValues, setDownloadFileFilterValues] =
    useState<TSearchParams>({
      PageSize: 10,
      Page: 1,
      StartDate: moment().subtract(30, "days").format("yy-MM-DD"),
      EndDate: moment().format("yy-MM-DD"),
      TextFilter: "",
      Status: null,
      PayeeType: null,
    });

  // Function to hide the popover
  const hidePopover = () => {
    setOpenDownloadPopover(false);
  };

  // Function to download the file
  const downloadFileFunction = async (fileType: string) => {
    if (showDownloadBottomSheet) {
      setShowDownloadBottomSheet(false);
    }
    if (openDownloadPopover) {
      hidePopover();
    }
    const downloadPayoutPayload = {
      downloadType: fileType === "pdf" ? 1 : 0,
      Status: downloadFilterFilterValues?.Status || null,
      StartDate: downloadFilterFilterValues?.StartDate,
      EndDate: downloadFilterFilterValues?.EndDate || "",
      TextFilter: downloadFilterFilterValues?.TextFilter || "",
      VendorId: downloadFilterFilterValues?.VendorId || "",
      PayeeType: downloadFilterFilterValues?.PayeeType || null,
    };
    await mutateDownloadAsync(downloadPayoutPayload);
  };

  // Function to handle showing and hiding the popover or handle showDownloadBottomSheet
  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (screenSize > 768) {
      setOpenDownloadPopover(newOpen);
    } else {
      setShowDownloadBottomSheet(true);
    }
  };


  const { mutateAsync: mutateDownloadAsync, data , isLoading} = useMutation(
    downloadBulkPayout,
    {
      onSuccess: (response) => {
        downloadFile(response?.data, "Payouts");
        toast.success(`${data?.message || "Downloaded successfully"}`);
      },
      onError: (error: AxiosError<{ message: string }>) => {
        if (error?.response?.status === 400) {
          toast.error("No data to download");
        } else {
          toast.error("Error downloading");
        }
      },
    }
  );
  return (
    <ContentLayout
      headerChildren={
        <>
          <Popover
            content={
              <DownloadPopoverComponent
                downloadFileFunction={downloadFileFunction}
              />
            }
            trigger="click"
            open={openDownloadPopover}
            onOpenChange={handlePopoverOpenChange}
            overlayStyle={{ width: "140px" }}>
            <div>
              <OutlineButton
                icon={<DownloadIcon />}
                // label={"Download"}
                label={isLoading? "Downloading..." : "Download"}
                className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
                hideIconOnDesktop={false}
                hideTextOnMobile={true}
              />
            </div>
          </Popover>
          {userPermissions?.includes("Permission.Request.Initiate") && (
            <PrimaryButton
              icon={<PayoutButtonIcon />}
              label={"New bulk payment"}
              className="font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
              onClick={() => navigate("/initiate-bulk-payout")}
              hideIconOnDesktop={true}
              hideTextOnMobile={true}
            />
          )}
        </>
      }>
      <BulkPayoutTable setDownloadFileFilterValues={setDownloadFileFilterValues} 
  />
      <BottomSheet
        openBottomSheet={showDownloadBottomSheet}
        setOpenBottomSheet={setShowDownloadBottomSheet}>
        <DownloadPopoverComponent 
        downloadFileFunction={downloadFileFunction}   
 />
      </BottomSheet>
      
    </ContentLayout>
  );
};

export default BulkPayout;
