import React, { useState } from "react";
import { Checkbox, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
// import "../Table/Table/Table.scss";
import TableShimmer from "../Table/TableShimmer";
import { TableProps } from "../Table/types";

function DynamicTable<TData extends { id?: string | number }>({
    checked = false,
  tableData = [],
  fields,
  builder,
  isLoading = false,
  noDataMessage = "No records found to display",
  handleRowClick,
  startRow = 0,
  rows,
  allowRelativeRow = false,
}: {
  checked?: boolean;
  tableData: TData[];
  fields: { name: string; displayName: string }[];
  builder: (
    field: { name: string; displayName: string },
    item: TData,
    rowIndex: number,
    columnIndex: number
  ) => React.ReactNode;
  isLoading?: boolean;
  noDataMessage?: string;
  handleRowClick?: (item: TData) => void;
  startRow?: number;
  rows?: number;
  allowRelativeRow?: boolean;
}) {
  const numberOfRows = rows || tableData.length;
  const displayedData = tableData.slice(startRow, startRow + numberOfRows);

  const [selected, setSelected] = useState<string[]>([]);

  const handleSelectAllClick = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      const allIds = displayedData.map((item) => String(item.id));
      setSelected(allIds);
    } else {
      setSelected([]);
    }
  };

  const handleRowSelection = (event: CheckboxChangeEvent, id: string) => {
    const isSelected = selected.includes(id);
    const newSelected = isSelected
      ? selected.filter((item) => item !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  return (
    <div className="table-wrapper w-full">
      <table className="table">
        {/* Table Header */}
        <thead className="table-header-container rounded-[10px]">
          <tr>
            {checked && (
              <th>
                <Checkbox
                  checked={
                    displayedData.length > 0 &&
                    selected.length === displayedData.length
                  }
                  onChange={handleSelectAllClick}
                />
              </th>
            )}
            {fields.map((field) => (
              <th key={field.name}>{field.displayName}</th>
            ))}
          </tr>
        </thead>

        {/* Table Body */}
        <tbody className="large-table-body">
          {isLoading ? (
            <tr>
              <td colSpan={fields.length + (checked ? 1 : 0)} className="loading-row">
                <Spin size="large" />
              </td>
            </tr>
          ) : displayedData.length === 0 ? (
            <tr>
              <td colSpan={fields.length + (checked ? 1 : 0)} className="empty-row">
                {noDataMessage}
              </td>
            </tr>
          ) : (
            displayedData.map((item, rowIndex) => (
              <tr
                key={item.id || rowIndex} // Fallback to index if no unique ID is provided
                className={`table-body-container ${
                  allowRelativeRow ? "allow-relative" : ""
                }`}
                onClick={() => handleRowClick?.(item)}
              >
                {checked && (
                  <td>
                    <Checkbox
                      checked={selected.includes(String(item.id))}
                      onChange={(e) => handleRowSelection(e, String(item.id))}
                    />
                  </td>
                )}
                {fields.map((field, columnIndex) => (
                  <td key={`${item.id || rowIndex}-${field.name}`}>
                    {builder(field, item, rowIndex + startRow, columnIndex)}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default DynamicTable;
