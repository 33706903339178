import "./SidebarLogoutPopover.scss";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { getSavedState } from "../../utils/localStorage";
import { getFullNameInitials } from "../../utils/getInitials";


const SidebarLogoutPopover = ({ hidePopover }: { hidePopover: () => void }) => {
  const user = getSavedState("NSIBUser");
  const fullName = user?.fullName || "";
  const firstName = fullName?.split(" ")[0] || "";
  const email = user?.userName || "";

  return (
    <div className="popover-content">
      <div className="users-detail">
        <span className="icon-text">
          <p className="initials">{getFullNameInitials(fullName)}</p>
          <span className="others">
            <p>{firstName}</p>
            <p className="w-full line-clamp-1 text-ellipsis">{email}</p>
          </span>
        </span>
      </div>
      <hr />
      <button onClick={hidePopover} className="logout-button">
        <LogoutIcon />
        Logout
      </button>
    </div>
  );
};

export default SidebarLogoutPopover;
