import * as yup from "yup";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const ConfirmAccoutSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email")
    .matches(emailRegex, "Invalid email address"),
  fullName: yup
    .string()
    .matches(
      /^\s*\S+\s+\S+\s*$/,
      "Full name must have a first name and last name"
    )
    .required("Full name is required"),
  password: yup
    .string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(/[\W_]/, "Password must contain at least one special character")
    .test(
      "minLength",
      "Password must be at least 8 characters long",
      function (value) {
        if (value) {
          // If the value exists, check the minimum length
          return value.length >= 8;
        }
        // If the value is undefined or empty, no validation is needed
        return true;
      }
    ),
});
