import React, { ReactNode } from "react";
import "./PrimaryButton.scss";

interface IButton {
  label?: ReactNode;
  type?: any;
  isLoading?: boolean;
  disabled?: boolean;
  color?: string;
  className?: string;
  onClick?: () => void;
  width?: string;
  icon?: ReactNode;
  hideTextOnMobile?: boolean;
  hideIconOnDesktop?: boolean;
  filterMobileButton?: boolean;
}

const PrimaryButton = ({
  label,
  icon,
  onClick,
  type = "button",
  className = "",
  width,
  hideTextOnMobile,
  hideIconOnDesktop,
  filterMobileButton,
  disabled
}: IButton) => {
  // const buttonStyle = {
  //     width: width || '20%', // Default width
  // };
  const buttonStyle = width ? { width } : {}; // Apply style only if width is provided

  return (
    <button
      type={type}
      className={`custom-button ${
        filterMobileButton && "mobile-filter-button"
      } ${className}`}
      onClick={onClick}
      disabled={disabled ? disabled : false}
      style={buttonStyle}>
      {icon && (
        <span
          className={`button-icon ${hideIconOnDesktop && "hide-desktop-icon"}`}>
          {icon}
        </span>
      )}
      {label && (
        <span className={`${hideTextOnMobile && "hide-mobile-text"} `}>
          {label}
        </span>
      )}
    </button>
  );
};

export default PrimaryButton;
