import * as yup from "yup";

export const manualBulkPayoutSchema = (payeeType: string) =>
  yup.object().shape({
    tableData: yup
      .array()
      .of(
        yup.object().shape({
          payeeId: yup
            .object()
            .shape({
              value: yup.string().required("Please select an option"),
              label: yup.string(),
            })
            ,
          amountPayable: yup
            .number()
            .required("Payable amount is required")
            .min(0, "Payable amount must be greater than 0"),
          reason: yup.string().required("Reason is required"),
          supportingDocument: yup
            .mixed()
            .required("Supporting document is required"),
          vat: yup.boolean(),
          whtGeneralContract: yup.boolean(),
          whtConsultancyService: yup.boolean(),
          stampDuty: yup.boolean(),
          amount: yup
            .number()
            .typeError("Amount must be a valid number")
            .required("Amount is required")
            .min(0, "Amount must be greater than 0"),
        })
        .test(
          "at-least-one-tax",
          "You must select at least one tax",
          (row) => {
            // Apply the rule only if payeeType is "Vendor"
            if (payeeType === "Vendor") {
              return (
                row?.vat ||
                row?.whtGeneralContract ||
                row?.whtConsultancyService ||
                row?.stampDuty
              );
            }
            return true; // If payeeType is not "Vendor", skip this validation
          }
        )
      )
   
  });


