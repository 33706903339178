import { FC } from "react";

import "./DashboardHeader.scss";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Bill } from "../../assets/icons/billicon.svg";
import { ReactComponent as UsersGroupRounded } from "../../assets/icons/vendoricon.svg";
import { ReactComponent as ArchiveDownMinimlistic } from "../../assets/icons/requesticon.svg";
import { ReactComponent as PapersMinimalistic } from "../../assets/icons/auditlog.svg";
import { ReactComponent as Bell } from "../../assets/icons/notificationsicon.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/hamburger.svg";
import NsibLogo from "../../assets/images/nsib-logo.png";
import { useLocation } from "react-router-dom";
import {
  DashboardHeaderProps,
  DashboardNavLinkInformationType,
} from "../DashboardLayout/DashboardLayoutTypes";

const DashboardHeader: FC<DashboardHeaderProps> = ({
  children,
  toggleSider,
}) => {
  const { pathname } = useLocation();
  const dashboardHeaderLinkInformation: DashboardNavLinkInformationType[] = [
    {
      key: "/home",
      icon: <Home />,
      label: "Home",
      permissions: [], // No permissions required
    },
    {
      key: "/payouts",
      icon: <Bill />,
      label: "Payouts",
      permissions: ["Permission.Payout"],
    },
    {
      key: "/bulk-payout",
      icon: <Bill />,
      label: "Bulk Payout",
      permissions: ["Permission.Payout"],
    },
    {
      key: "/vendors",
      icon: <UsersGroupRounded />,
      label: "Payee",
      permissions: ["Permission.VendorInformation"],
    },
    {
      key: "/requests",
      icon: <ArchiveDownMinimlistic />,
      label: "Requests",
      permissions: ["Permission.Request"],
    },
    {
      key: "/audit-log",
      icon: <PapersMinimalistic />,
      label: "Audit Log",
      permissions: ["Permission.AuditLog"],
    },
    {
      key: "/notifications",
      icon: <Bell />,
      label: "Notifications",
      permissions: [], // No permissions required
    },
    {
      key: "/settings",
      icon: <Settings />,
      label: "Settings",
      permissions: ["Permission.SystemSetup"],
    },
  ];
  return (
    <header className="dashboard-header">
      <div className="mobile-content-container">
        <button className="sidebar-hamburger-button" onClick={toggleSider}>
          <HamburgerIcon />
        </button>
        {/* NSIB Logo */}
        <img src={NsibLogo} alt="NSIB Logo" className="mobile-nsib-logo" />
      </div>

      <div className="main-content">
        {/* This side holds the link being displayed */}
        {dashboardHeaderLinkInformation.map(
          (dashboardHeaderLink, id) =>
            (pathname === dashboardHeaderLink.key ||
              pathname.startsWith(`/${dashboardHeaderLink.key}`)) && (
              <div
                key={id}
                className={`path-holder ${pathname === "/home" && "hide"}`}>
                <span>{dashboardHeaderLink.icon}</span>
                <p>{dashboardHeaderLink.label}</p>
              </div>
            )
        )}
        {/* This side holds each page button being passed to the header */}
        <div className="page-button-holder">{children}</div>
      </div>
    </header>
  );
};

export default DashboardHeader;
