import { devInstance } from "../utils/axios/axiosInstance"




export const searchNotification = async (payload: any) => {
    const response = await devInstance.get('/Notification/Search', {params: payload})

    return  response.data
}


export const fetchNotificationTypes = async (payload: any) => {
    const response = await devInstance.get('/NotificationType/GetAll')

    return  response.data
}

export const putNotificationService = async (payload: any) => {
    const response = await devInstance.put('/Notification/NotificationSeen', payload)
    return  response.data
}

 