import { Segmented } from "antd";
import "./MobileFilterContainer.scss";
import { useState } from "react";
import PrimaryButton from "../Button/PrimaryButton";
import OutlineButton from "../OutlineButton/OutlineButton";
import { ReactComponent as CheckMark } from "../../assets/icons/smallcheckIcon.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightarrow.svg";
import { FilterSelectionType } from "../../pages/Payouts/types";
import CustomMobileDateRange from "../CustomMobileDateRange/CustomMobileDateRange";

interface MobileFilterContainerType {
  setShowOtherFilterBottomSheet: (showOtherFilterBottomSheet: boolean) => void;
  customizedOptionAction?: () => void;
  optionsData: {
    type: string;
    options: { label: string; value: string | number | Date[] }[];
  }[];
  applyFilterAction: (selectedOption: FilterSelectionType) => void;
}

const MobileFilterContainer = ({
  setShowOtherFilterBottomSheet,
  optionsData,
  customizedOptionAction,
  applyFilterAction,
}: MobileFilterContainerType) => {
  const [visibleOption, setVisibleOption] = useState(optionsData[0].type);
  const [filterSelection, setFilterSelection] = useState<FilterSelectionType>(
    {}
  );
  const [showCustomDateFilter, setShowCustomDateFilter] = useState(false);

  const handleSelectChange = (
    type: string,
    option: { label: string; value: string | number | Date[] }
  ) => {
    if (option.label !== "Custom") {
      setFilterSelection((prevState) => ({
        ...prevState,
        [type]: { label: option.label, value: option.value },
      }));
    } else if (option.label === "Custom" && type === "date") {
      setShowCustomDateFilter(true);
    } else {
      if (customizedOptionAction) {
        customizedOptionAction();
      }
    }
  };

  const applySelectedFilterOptions = () => {
    applyFilterAction(filterSelection);
    setShowOtherFilterBottomSheet(false);
  };

  const clearAllSelectedOptions = () => {
    setFilterSelection({});
    setShowCustomDateFilter(false);
  };

  const setDateValue = (dateValue: Date[]) => {
    setFilterSelection((prevState) => ({
      ...prevState,
      date: { label: "Custom", value: [dateValue[0], dateValue[1]] },
    }));
  };

  return (
    <div className="mobile-filter-container">
      {showCustomDateFilter ? (
        <CustomMobileDateRange setDateValue={setDateValue} />
      ) : (
        <>
          <Segmented<string>
            options={optionsData.map((option) => `${option.type}`)}
            onChange={(value) => {
              setVisibleOption(value);
            }}
          />
          <div className="option-container">
            {optionsData.map(
              (optionGroup) =>
                optionGroup.type === visibleOption && (
                  <div key={optionGroup.type}>
                    {optionGroup.options.map((option, id) => (
                      <p
                        key={id}
                        className={`option ${
                          filterSelection[visibleOption]?.label === option.label
                            ? "selected"
                            : ""
                        }`}
                        onClick={() =>
                          handleSelectChange(visibleOption, option)
                        }>
                        {option.label}{" "}
                        <span>
                          {option.label === "Custom" ? (
                            <RightArrow className="custom-arrow" />
                          ) : (
                            filterSelection[visibleOption]?.label ===
                              option.label && <CheckMark />
                          )}
                        </span>
                      </p>
                    ))}
                  </div>
                )
            )}
          </div>
        </>
      )}

      <div className="button-container">
        <OutlineButton
          label={"Clear all"}
          className="w-full font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm"
          onClick={clearAllSelectedOptions}
        />
        <PrimaryButton
          label={"Apply"}
          className={`w-full font-medium !px-2.5 md:px-3.5 !py-1.5 text-nowrap text-sm`}
          disabled={
            filterSelection &&
            !filterSelection?.date?.value &&
            showCustomDateFilter
          }
          onClick={applySelectedFilterOptions}
        />
      </div>
    </div>
  );
};

export default MobileFilterContainer;
