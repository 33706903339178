export const getPayoutStatusText = (status: number) => {
  return status === 1
    ? "Successful"
    : status === 2
    ? "Failed"
    : status === 3
    ? "Pending Approval"
    : "Declined";
};


export const getStatusLabel = (status: number) => {
  return status === 0
    ? "Pending"
    : status === 1
    ? "Approved"
    : status === 2
    ? "Declined"
    : "Pending";
};


export const getVendorStatusLabel = (status: number) => {
  return status === 0
    ? "Inactive"
    : status === 1
    ? "Active"
    : "Unknown Status";
};

 