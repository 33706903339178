import "./DownloadPopoverComponent.scss";

interface DownloadPopoverComponentType {
  downloadFileFunction: (fileType: string) => void;
}

const DownloadPopoverComponent = ({
  downloadFileFunction,
}: DownloadPopoverComponentType) => {
  return (
    <div className="download-container">
      <div className="popover-item" onClick={() => downloadFileFunction("csv")}>
        <span className="popover-text">CSV</span>
      </div>
      <div className="popover-item" onClick={() => downloadFileFunction("pdf")}>
        <span className="popover-text">PDF</span>
      </div>
    </div>
  );
};

export default DownloadPopoverComponent;
