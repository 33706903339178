import { devInstance } from "../utils/axios/axiosInstance";

export const searchVendor = async (payload: any) => { 
    const response = await devInstance.get('/Payee/Search', {params: payload})  
    return response 
}


export const downloadVendor = async (payload: any) => {
    const response = await devInstance.get('/Payee/Download', {params: payload})  
    return response.data; 
  };

  export const addVendor = async (payload: any) => {
    const response = await devInstance.post('/Payee/Add', payload)
    return  response.data
}

export const fetchBanks = async (payload: any) => {
    const response = await devInstance.get('/Bank')
    return response
}

export const DeactivateVendor = async (payload: any) => {
    const response = await devInstance.put(`/Payee/Deactivate?id=${payload.ids}`, payload)
    return response
}

export const activateVendor = async (payload: any) => {
    const response = await devInstance.put(`/Payee/Activate?id=${payload.ids}`, payload)
    return response
}

export const fetchCurrencies = async (payload: any) => {
    const response = await devInstance.get('/Currency')
    return response
}

export const uploadVendorfiles = async (formData: FormData) => {
    const response = await devInstance.post('/Payee/UploadFileToS3', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  };

export const verifyBankAccount = async (payload: any) => {
    const response = await devInstance.post('/Payee/ValidateBankAccount', payload)
    return response
}

export const updateVendor = async (payload: any) => {
    const response = await devInstance.put('/Payee/Update', payload)
    return  response.data
}

export const uploadVendorData = async (formData: FormData) => {
    const response = await devInstance.post('/Payee/UploadData', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  };