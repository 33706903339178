
export enum WORKFLOW_STATUS_VALUE {
  PENDING = 0,
  APPROVE = 1,
  DECLINED = 2,
}


export enum WORKFLOW_STATUS {
  PENDING = 'Pending',
  APPROVE = 'Approved',
  DECLINED = 'Declined',
}

export enum PAYOUT_STATUS_VALUE {
  SUCCESSFUL = 1,
  FAILED = 2,
  PENDING = 3,
  DECLINED = 4,
}

export enum PAYOUT_STATUS {
  SUCCESSFUL = 'Successful',
  PENDING = 'Pending Approval',
  DECLINED = 'Declined',
  FAILED = 'Failed',
}


export const vendorStatuses = [
  {label: 'All', value: -1},
  {label: 'Inactive', value: 0},
  {label: 'Active', value: 1},
  // {label: 'Awaiting Approval', value: 2},

]


// OPTIONS
export const statuses = [
  {label: 'All', value: -1,},
  {label: 'Pending', value: 0},
  {label: 'Approved', value: 1},
  {label: 'Declined', value: 2},
]

export const payoutStatuses = [
  {label: 'All', value: -1,},
  {label: 'Successful', value: 1},
  {label: 'Failed', value: 2},
  {label: 'Pending Approval', value: 3},
  {label: 'Declined', value: 4},
]

export const payeeStatuses = [
  {label: 'All', value: -1},
  {label: 'vendor', value: 1},
  {label: 'employee', value: 2},
]