import { useLocation, useParams } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import LandingLayout from "../../components/LandingLayout/LandingLayout";
import CustomInput from "../../components/UIKits/CustomInput/CustomInput";
import { Spinner } from "../../components/UIKits/Spinner/Spinner";
import NSIBlogo from "../../assets/images/nsib-logo.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmAccoutSchema } from "../../validation-schemas/confirm-account.schema";
import { confirmAccount } from "../../services/auth";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { saveLocalState } from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ConfirmAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the query string
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const decodedCToken = encodeURIComponent(token!);
  const fullName = queryParams.get("fullname");
  const email = queryParams.get("email");

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ConfirmAccoutSchema),
    defaultValues: {
      email: email!,
      fullName: fullName!,
      password: "",
    },
  });

  //confirm account
  const {
    isLoading,
    mutate: confirmAccountData,
    isError,
    error: errorResponse,
  } = useMutation((payload: any) => confirmAccount(payload), {
    onSuccess: async (data) => {
      if (data.statusCode === 200) {
        await saveLocalState("NSIBUser", {
          ...data,
        });
        // window.location.href = "/home";
        navigate("/login");
      } else {
        toast.error(
          data?.message || "Error logging in, please try again later."
        );
      }
    },
    onError: (error: AxiosError<{ message: string }>) => {
      console.log({ error });
      toast.error("Error logging in, please try again later.");
    },
  });

  const onSubmit = (data: any) => {
    const payload = {
      userToken: decodedCToken,
      password: data.password,
      confirmPassword: data.password,
    };
    confirmAccountData(payload);
  };

  return (
    <LandingLayout
    disableScroll={false}
    >
      <div className="h-[100%]">
        <div className="lg:w-[350px] w-full h-full flex-col gap-8 inline-flex login-container md:ml-[20%] ml-0">
          <img src={NSIBlogo} alt="NSIB Logo" className="w-[171px] mt-[3%]" />

          <p className="login-text text-[31px] font-semibold mt-[-10px]">
            Set up your account
          </p>
          <p className="text-[#0d0d0d99] text-[14px] mt-[-20px]">
            You are required to completed your account setup. Create a password
            and log into your new dashboard
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-start items-start gap-[2px] mt-[-20px]">
              <div className="w-full lg:w-[350px] mb-3">
                <CustomInput
                  type="text"
                  name="email"
                  label="Email address"
                  placeholder=""
                  control={control}
                  errorMessage={errors.email?.message}
                  isDisabled
                />
              </div>

              <div className="w-full lg:w-[350px] mb-3">
                <CustomInput
                  type="text"
                  name="fullName"
                  label="Full name"
                  placeholder=""
                  control={control}
                  errorMessage={errors.fullName?.message}
                  isDisabled
                />
              </div>
              <div className="w-full lg:w-[350px] mb-3">
                <CustomInput
                  type="password"
                  name="password"
                  label="Password"
                  placeholder=""
                  control={control}
                  errorMessage={errors.password?.message}
                />
              </div>
            </div>

            <div className="mt-8 text-center">
              <PrimaryButton
                label={isLoading ? <Spinner /> : "Login"}
                // label="log"
                className="!py-2.5"
                type="submit"
                width="100%"
              />
            </div>
          </form>
        </div>
      </div>
    </LandingLayout>
  );
};

export default ConfirmAccount;
