import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/icons/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import { ReactComponent as CautionIcon } from "../../assets/icons/cautionIcon.svg";
import BulkPayoutLayout from "../../components/BulkPayoutLayout/BulkPayoutLayout";
import Table from "../../components/Table/Table";
import { formatCurrency } from "../../utils/helper";
import { TableBulkPayoutReviewData, BulkPayoutReviewRowData } from "./types";
import { useState } from "react";
import BulkPayoutReviewSideDrawer from "../../components/BulkPayoutReviewSideDrawer/BulkPayoutReviewSideDrawer";
import { Modal } from "antd";
import OutlineButton from "../../components/OutlineButton/OutlineButton";
import PrimaryButton from "../../components/Button/PrimaryButton";
import StatusSetter from "../../components/StatusSetter/StatusSetter";
import { validateBulkPayeeDetail } from "../../services/bulk-payout.service";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const BulkPayoutReview = () => {
  const { payeeType, bulkPayoutReviewData } = useLocation().state || {};
  const [showContinuePopup, setShowContinuePopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeRow, setActiveRow] = useState<BulkPayoutReviewRowData | null>();
  const tableHead: {
    name: keyof TableBulkPayoutReviewData;
    displayName: string;
  }[] = [
    { name: "payeeId", displayName: "Payee ID" },
    { name: "payeeName", displayName: "Payee name" },
    { name: "beneficiaryBankCode", displayName: "Bank code" },
    { name: "accountNumber", displayName: "Account number " },
    { name: "payableAmount", displayName: "Amount" },
    ...(payeeType === "Vendor"
      ? ([
          { name: "vat", displayName: "VAT (7.5%)" },
          { name: "whtGeneralContract", displayName: "WHT (5%)" },
          { name: "whtConsultancyService", displayName: "WHT (10%)" },
          { name: "stampDuty", displayName: "Stamp duty (1%)" },
        ] as { name: keyof TableBulkPayoutReviewData; displayName: string }[])
      : []),
    { name: "reason", displayName: "Reason" },
    { name: "errorExists", displayName: "Import status" },
    { name: "rowNumber", displayName: "" },
  ];

  const navigate = useNavigate();

  const checkError = (field: string, errors: { propertyName: string }[]) => {
    return errors?.some(
      (error) => error.propertyName.toLowerCase() === field.toLowerCase()
    )
      ? "text-caution"
      : "";
  };

  const deleteRowData = (
    event: React.MouseEvent<HTMLDivElement>,
    rowNumber: number
  ) => {
    event.stopPropagation();
    setActiveRow(
      bulkPayoutReviewData?.result?.dataItems.find(
        (item: BulkPayoutReviewRowData) => item?.rowNumber === rowNumber
      )
    );
    setShowDeleteModal(true);
  };

  const editRowData = (
    event: React.MouseEvent<HTMLDivElement>,
    rowNumber: number
  ) => {
    event.stopPropagation();
    setActiveRow(
      bulkPayoutReviewData?.result?.dataItems.find(
        (item: BulkPayoutReviewRowData) => item?.rowNumber === rowNumber
      )
    );
    setShowEditModal(true);
  };

  const updateRowEntry = (newRowData: BulkPayoutReviewRowData) => {
    bulkPayoutReviewData.result.dataItems =
      bulkPayoutReviewData?.result?.dataItems?.map(
        (item: BulkPayoutReviewRowData) =>
          item.rowNumber === newRowData.rowNumber ? newRowData : item
      );
  };

  const deleteRowEntry = (rowNumberToDelete: number) => {
    bulkPayoutReviewData.result.dataItems =
      bulkPayoutReviewData?.result?.dataItems?.filter(
        (item: BulkPayoutReviewRowData) => item.rowNumber !== rowNumberToDelete
      );
    setShowDeleteModal(false);
    setActiveRow(null);
  };

  const { mutate: mutateAsync, isLoading: isSubmitingData } = useMutation(
    validateBulkPayeeDetail,
    {
      onSuccess: (response) => {
        if (response?.successful === false) {
          toast?.error(
            `Payee detail updated however, ${
              bulkPayoutReviewData?.result?.dataItems.filter(
                (item: BulkPayoutReviewRowData) => item.errorExists === true
              ).length | 0
            } remains unresolved!`
          );
        } else {
          navigate("/bulk-payout-summary", {
            state: {
              bulkPayoutReviewData: response,
              payeeType: payeeType,
            },
          });
        }
      },
      onError: (error: AxiosError<{ message: string }>) => {
        if (error?.response) {
          toast.error(error?.response?.data?.message);
        }else{
          toast.error("An issue occurred, please try again later!")
        }
      },
    }
  );

  const ValidateFullRecord = () => {
    mutateAsync({
      payeeType: payeeType === "Vendor" ? 1 : 2,
      payeeDetails: bulkPayoutReviewData.result.dataItems,
    });
  };

  return (
    <BulkPayoutLayout
      additionalClassName="bulk-review-page"
      topCancelButtonAction={() => navigate("/bulk-payout")}
      bottomCancelButtonAction={() => setShowCancelPopup(true)}
      disableContinue={
        bulkPayoutReviewData?.result?.dataItems.filter(
          (item: BulkPayoutReviewRowData) => item.errorExists === true
        ).length > 0 || bulkPayoutReviewData?.result?.dataItems?.length === 0
      }
      isContinueLoading={isSubmitingData}
      continueButtonAction={() => setShowContinuePopup(true)}>
      <div className="mt-[50px] ">
        <p className="text-2xl font-semibold text-[#0D0D0D]">Review upload</p>
        <p className="text-base text-[#0D0D0D80] mt-2">
          Kindly review your uploaded file
        </p>
        <div
          className={`mt-6  p-4 rounded-2xl ${
            bulkPayoutReviewData?.result?.dataItems.filter(
              (item: BulkPayoutReviewRowData) => item.errorExists === true
            ).length === 0
              ? "bg-[#fafafa]"
              : "bg-[#FFF8EF]"
          }`}>
          {bulkPayoutReviewData?.result?.dataItems.filter(
            (item: BulkPayoutReviewRowData) => item.errorExists === true
          ).length > 0 && (
            <p className="text-[#0D0D0D] flex gap-2 items-center">
              <span className="w-[28px] h-[28px]">
                <CautionIcon className="w-[28px] h-[28px]" />
              </span>
              We found{" "}
              {bulkPayoutReviewData?.result?.dataItems.filter(
                (item: BulkPayoutReviewRowData) => item.errorExists === true
              ).length | 0}{" "}
              errors in your file
            </p>
          )}
          <div className="mt-4 space-y-0.5 !font-normal">
            <p className="!font-light text-[#0D0D0D99]">
              Total rows: {bulkPayoutReviewData?.result?.dataItems?.length | 0}
            </p>
            <p className="!font-light text-[#0D0D0D99]">
              Errors found:{" "}
              <span className="text-[#F57E20]">
                {bulkPayoutReviewData?.result?.dataItems.filter(
                  (item: BulkPayoutReviewRowData) => item.errorExists === true
                ).length | 0}
              </span>
            </p>
            <p className="!text-[#1A1B1DCC]">
              Kindly resolve all errors before proceeding
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 main-table-wrapper">
        <Table<TableBulkPayoutReviewData, BulkPayoutReviewRowData>
          fields={tableHead}
          tableData={bulkPayoutReviewData?.result?.dataItems || []}
          handleRowClick={(item: BulkPayoutReviewRowData) => {
            setActiveRow(item);
            setShowEditModal(true);
          }}
          // isLoading={isPersonalPayoutLoading}
          allowRelativeRow={true}
          rows={bulkPayoutReviewData?.result?.dataItems?.length || 0}
          builder={(field, data) => {
            switch (field.name) {
              case "payeeId":
                return (
                  <span className={checkError("payeeId", data.errors)}>
                    {data?.payeeId}
                  </span>
                );
              case "payeeName":
                return (
                  <span
                    className={`${checkError(
                      "payeeName",
                      data.errors
                    )} max-w-[300px] text-wrap`}>
                    {data?.payeeName}
                  </span>
                );
              case "beneficiaryBankCode":
                return (
                  <span
                    className={checkError("beneficiaryBankCode", data.errors)}>
                    {data?.beneficiaryBankCode}
                  </span>
                );
              case "accountNumber":
                return (
                  <span className={checkError("accountNumber", data.errors)}>
                    {data?.accountNumber}
                  </span>
                );
              case "payableAmount":
                return (
                  <div className={checkError("payableAmount", data.errors)}>
                    {formatCurrency(data.payableAmount)}
                  </div>
                );
              case "vat":
                return payeeType === "Vendor" ? (
                  <span className={checkError("vat", data.errors)}>
                    {data?.vat === true ? "Yes" : "No"}
                  </span>
                ) : null;
              case "whtGeneralContract":
                return payeeType === "Vendor" ? (
                  <span
                    className={checkError("whtGeneralContract", data.errors)}>
                    {data?.whtGeneralContract === true ? "Yes" : "No"}
                  </span>
                ) : null;
              case "whtConsultancyService":
                return payeeType === "Vendor" ? (
                  <span
                    className={checkError(
                      "whtConsultancyService",
                      data.errors
                    )}>
                    {data?.whtConsultancyService === true ? "Yes" : "No"}
                  </span>
                ) : null;
              case "stampDuty":
                return payeeType === "Vendor" ? (
                  <span className={checkError("stampDuty", data.errors)}>
                    {data?.stampDuty === true ? "Yes" : "No"}
                  </span>
                ) : null;
              case "reason":
                return (
                  <span
                    className={`${checkError(
                      "reason",
                      data.errors
                    )} block text-wrap max-w-[350px]`}>
                    {data?.reason || "---"}
                  </span>
                );
              case "errorExists":
                return (
                  <span>
                    <StatusSetter
                      incomingStatus={data?.errorExists === true ? 3 : 1}
                      statusLabel={
                        data?.errorExists === true ? "Failed" : "Successful"
                      }
                    />
                    {data?.errorExists === true && (
                      <span className="flex w-full h-full absolute border border-dashed border-caution top-0 bottom-0 right-0 left-0 m-auto"></span>
                    )}
                  </span>
                );
              case "rowNumber":
                return (
                  <span className="relative flex gap-4 z-20 justify-end">
                    <span
                      onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                        editRowData(event, data.rowNumber)
                      }>
                      <EditIcon className="fill-[#0D0D0D] hover:fill-caution" />
                    </span>
                    <span
                      onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                        deleteRowData(event, data.rowNumber)
                      }>
                      <DeleteIcon className="fill-[#0D0D0D] hover:fill-caution" />
                    </span>
                  </span>
                );
              default:
                return data[field?.name];
            }
          }}
        />
      </div>
      {activeRow && showEditModal && (
        <BulkPayoutReviewSideDrawer
          openDraw={showEditModal}
          setOpenDrawer={setShowEditModal}
          selectedRowData={activeRow}
          setSelectedRowData={setActiveRow}
          payeeType={payeeType}
          updateRowEntry={updateRowEntry}
        />
      )}
      {activeRow && showDeleteModal && (
        <Modal
          title={<p className="text-2xl font-semibold">Delete Row?</p>}
          open={showDeleteModal}
          onCancel={() => {
            setShowDeleteModal(false);
            setActiveRow(null);
          }}
          onOk={() => setShowDeleteModal(false)}
          footer={null}>
          <p className="text-[#0D0D0D99] mt-6 mb-10">
            Are you sure you want to delete this row? Deleting this row cannot
            be undone
          </p>
          <div className="flex justify-end gap-3 items-center">
            <OutlineButton
              onClick={() => {
                setShowDeleteModal(false);
                setActiveRow(null);
              }}
              label={"Cancel"}
              className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
            />
            <PrimaryButton
              onClick={() => deleteRowEntry(activeRow.rowNumber)}
              label={"Yes, delete"}
              className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block !bg-[#FF1507] !text-white outline outline-[#FF1507] border border-[#FFFFFF33] focus:bg-[#FF1507] focus:shadow-none"
            />
          </div>
        </Modal>
      )}
      {showContinuePopup && (
        <Modal
          title={<p className="text-2xl font-semibold">Continue to summary?</p>}
          open={showContinuePopup}
          onCancel={() => {
            setShowContinuePopup(false);
          }}
          onOk={() => {
            setShowContinuePopup(false);
            ValidateFullRecord();
          }}
          footer={null}>
          <p className="text-[#0D0D0D99] mt-6 mb-10">
            Are you sure you want to proceed? You won’t be able to make any
            changes once you do so. Please review all details before proceeding
          </p>
          <div className="flex justify-end gap-3 items-center">
            <OutlineButton
              onClick={() => {
                setShowContinuePopup(false);
              }}
              label={"Cancel"}
              className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
            />
            <PrimaryButton
              onClick={() => {
                setShowContinuePopup(false);
                ValidateFullRecord();
              }}
              label={"Yes, Proceed"}
              className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
            />
          </div>
        </Modal>
      )}
      {showCancelPopup && (
        <Modal
          title={<p className="text-2xl font-semibold">Close payout?</p>}
          open={showCancelPopup}
          onCancel={() => {
            setShowCancelPopup(false);
          }}
          onOk={() => {
            setShowCancelPopup(false);
          }}
          footer={null}>
          <p className="text-[#0D0D0D99] mt-6 mb-10">
            You will lose all items entered on this payment if you choose to
            close now
          </p>
          <div className="flex justify-end gap-3 items-center">
            <OutlineButton
              onClick={() => {
                setShowCancelPopup(false);
              }}
              label={"Cancel"}
              className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
            />
            <PrimaryButton
              onClick={() => {
                setShowCancelPopup(false);
                navigate("/initiate-bulk-payout");
              }}
              label={"Close Payout"}
              className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm md:!block"
            />
          </div>
        </Modal>
      )}
    </BulkPayoutLayout>
  );
};

export default BulkPayoutReview;
