import "./BottomSheet.scss";
import { Drawer } from "antd";
import { ReactNode } from "react";
import { ReactComponent as CloseOutlined } from "../../assets/icons/cancelicon.svg";

interface BottomSheetType {
  openBottomSheet: boolean;
  setOpenBottomSheet: (openBottomSheet: boolean) => void;
  children: ReactNode;
  onCloseAction?: () => void;
  title?: string;
}

const BottomSheet = ({
  openBottomSheet,
  setOpenBottomSheet,
  children,
  title,
  onCloseAction,
}: BottomSheetType) => {
  const onClose = () => {
    setOpenBottomSheet(false);
    if (onCloseAction) {
      onCloseAction();
    }
  };

  return (
    <Drawer
      placement="bottom"
      closable={false}
      onClose={onClose}
      open={openBottomSheet}>
      <div className="bottom-sheet-top">
        <p className="title">{title}</p>
        <button onClick={onClose} className="bottom-sheet-cancel-button">
          <CloseOutlined />
        </button>
      </div>
      {children}
    </Drawer>
  );
};

export default BottomSheet;
