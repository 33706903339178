import React, { FC, useState, useEffect } from "react";
import OutlineButton from "../OutlineButton/OutlineButton";
import FileUpload from "../FileUpload/FileUpload";
import "./InitiateCard.scss"

interface UploadedFileType {
  file: File;
  name: string;
  size: number;
  type: string;
  base64Format: string;
}

interface InitiateCardPropType {
  handleClick?: () => void;
  label: string;
  downloadTemplate?: boolean;
  handleDownload?: () => void;
  bulkPayeeFile?: UploadedFileType[];
  setBulkPayeeFile?: (bulkPayeeFile: UploadedFileType[]) => void;
}

const InitiateCard = ({
  handleClick,
  label,
  downloadTemplate,
  handleDownload,
  bulkPayeeFile,
  setBulkPayeeFile,
}: InitiateCardPropType) => {
  const addPayeeDocument = () => {};
  return (
    <div
      className={`initiate-card h-32 w-full text-center rounded-xl  justify-center items-center text-nowrap ${
        bulkPayeeFile && bulkPayeeFile.length > 0
          ? "bg-white"
          : "bg-[#f9f9f9] border border-[#f0f0f0]"
      }`}>
      <div className="flex h-full flex-col items-center justify-center gap-2">
        {!downloadTemplate && (
          <OutlineButton
            label={label}
            onClick={!downloadTemplate ? handleClick : addPayeeDocument}
            className="!text-sm"
          />
        )}
        {downloadTemplate && bulkPayeeFile && setBulkPayeeFile && (
          <>
            <FileUpload
              supportingDocument={bulkPayeeFile}
              setSupportingDocument={setBulkPayeeFile}
              allowFileType=".csv"
              allowOnlyOneFile={true}
              customButtonLabel="Bulk upload"
              showToast={true}
            />
            {bulkPayeeFile && bulkPayeeFile.length === 0 && (
              <div className="flex gap-1">
                <span
                  className="text-[#5F38FA] text-xs font-normal font-['Archivo'] leading-[14.40px] text-nowrap cursor-pointer hover:opacity-80 transition-all duration-300 ease-in"
                  onClick={handleDownload}>
                  Download{" "}
                </span>
                <span className="text-[#848484] text-xs font-normal font-['Archivo'] leading-[14.40px] text-nowrap">
                  file template
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InitiateCard;
