import "./StatusSetter.scss"
import { getStatusStyle } from "../../utils/getStatusStyleName";


interface StatusSetterType{
    incomingStatus: number,
    statusLabel?: string
}

const StatusSetter = ({incomingStatus, statusLabel}: StatusSetterType) => {
    const {styles, label} = getStatusStyle(incomingStatus, statusLabel);
  return (
    <div className="flex items-center px-[8px] py-[6px] status-setter">
      <div className={styles}></div>
      <p className="ml-[1px] text-inherit">{label}</p>
    </div>
  );
};

export default StatusSetter;
