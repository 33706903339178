export const getInitials = (firstName: string, lastName: string) => {
  // Get the first letter of each name
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();

  // Concatenate and return the initials
  return `${firstInitial}${lastInitial}`;
};

export const getFullNameInitials = (fullName: string) => {
  // Split the full name into first and last names
  const names = fullName?.split(" ");
  const firstName = names && names[0];
  const lastName = names?.length > 1 ? names[names.length - 1] : "";

  // Get the first letter of each name
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();

  // Concatenate and return the initials
  return `${firstInitial}${lastInitial}`;
};

export const getVendorNameInitials = (vendorName: string) => {
  // Split the name into words
  const words = vendorName.split(" ");

  // Initialize an empty array to hold the initials
  let initials = [];

  // Iterate over each word
  for (let word of words) {
    // Use a regular expression to remove special characters
    const cleanedWord = word.replace(/[.&]/g, "");

    // Check if the cleaned word is not empty and not a conjunction (like 'and')
    if (cleanedWord.length > 0 && cleanedWord.toLowerCase() !== "and") {
      // Get the first letter of the cleaned word and convert it to uppercase
      initials.push(cleanedWord[0].toUpperCase());
    }

    // Stop after getting the initials of the first two words
    if (initials.length === 2) {
      break;
    }
  }

  // Join the initials to form a string
  return initials.join("");
};
