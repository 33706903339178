import React, { ReactElement } from "react";
import "./SideDrawer.scss";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrowleft.svg";
import { ReactComponent as CloseOutlined } from "../../assets/icons/cancelicon.svg";

interface IDrawer {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  showCloseButton?: boolean;
}

export function SideDrawer({ isOpen, setIsOpen, showCloseButton = true, children }: IDrawer): ReactElement {
  return (
    <div
      className={`side-drawer 
         fixed  overflow-hidden z-40 bg-gray-900 bg-opacity-5 inset-0  transform ease-in-out 
        ${isOpen ? "duration-500 translate-x-0" : " transition-all delay-500 opacity-0 translate-x-full  "}
      `}
    >
      <div
        className={
          " w-screen overflow-y-scroll max-w-lg right-0 absolute bg-white h-full z-50  delay-400 duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        {showCloseButton ?  (
          <button
            className="cancel-button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CloseOutlined className="x-icon !hidden md:!block" />
            <ArrowLeft className="arrow-icon !block md:!hidden" />
          </button>
        ) : null}
        {children}
      </div>
      <div
        className="w-screen h-full cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </div>
  );
}
