


export const permissions = {
    SETTING_USER: "Permission.Setting.User",
    SETTING_APPROVAL_WORKFLOW: "Permission.Setting.ApprovalWorkFlow",
    SETTING_TOKEN_AUTHENTICATION: "Permission.Setting.TokenAuthentication",
    AUDIT_LOG: "Permission.AuditLog",
    REQUEST: "Permission.Request",
    PAYOUT: "Permission.Payout",
    VENDOR_INFORMATION: "Permission.VendorInformation",
    HOME: "Permission.Home",
    SYSTEM_SETUP: "Permission.SystemSetup",
    REQUEST_APPROVE: "Permission.Request.Approve",
    REQUEST_INITIATE: "Permission.Request.Initiate"
};

export const roles = {
    vgg_admin: [
        permissions.SETTING_USER,
        permissions.SETTING_APPROVAL_WORKFLOW,
        permissions.AUDIT_LOG,
        permissions.HOME,
        permissions.SYSTEM_SETUP
    ],
    clientuser: [
        permissions.SETTING_USER,
        permissions.AUDIT_LOG,
        permissions.REQUEST,
        permissions.PAYOUT,
        permissions.VENDOR_INFORMATION,
        permissions.HOME
    ],
    clientadmin: [
        permissions.SETTING_USER,
        permissions.SETTING_APPROVAL_WORKFLOW,
        permissions.SETTING_TOKEN_AUTHENTICATION,
        permissions.AUDIT_LOG,
        permissions.REQUEST,
        permissions.VENDOR_INFORMATION,
        permissions.HOME,
        permissions.SYSTEM_SETUP
    ]
};
