import { ReactComponent as DefaultBankIcon } from "../../../assets/icons/defaultbank.svg";

import "./RenderBankWithIcon.scss";
import { bankDataType } from "./types";
import useBanks from "../../../hooks/useBanks";

interface RenderBankWithIconType {
  bankName?: string;
  bankId?: number | null;
  bankLogo?: string;
  hideText?: boolean
}

const RenderBankWithIcon = ({
  bankName,
  bankId,
  bankLogo,
  hideText,
}: RenderBankWithIconType) => {
  const { banks } = useBanks();

  const vendorsBankLogo = banks
    ? banks.find((bank: bankDataType) =>
        bankId ? bankId === bank?.id : bankName === bank?.name
      )
    : { logo: null };

  return (
    <p className={`bank-generator w-full line-clamp-1 text-ellipsis`}>
      <span className="logo">
        {bankLogo ? (
          <img src={bankLogo} alt="" /> 
        ) : banks && vendorsBankLogo?.logo ? (
          <img src={vendorsBankLogo?.logo} alt="" /> 
        ) : (
          <DefaultBankIcon />
        )}
      </span>
      <span className="w-full line-clamp-2 text-ellipsis">
        {hideText ? null : bankName || "N/A"}
      </span>
    </p>
  );
};

export default RenderBankWithIcon;
