import { ReactComponent as DocumentIcon } from "../../../assets/icons/document.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icons/imageIcon.svg";
import OutlineButton from "../../OutlineButton/OutlineButton";
import "./FileInfo.scss";
interface UploadedFileType {
  uploadedDocument: { file: File; name: string; size: number; type: string };
  removeFile: (index: number) => void;
  index: number;
}

const FileInfo = ({
  uploadedDocument,
  removeFile,
  index,
}: UploadedFileType) => {
  return (
    <div className="file-info">
      <div className="text-part">
        <div className="doc-icon">
          { uploadedDocument.type === "PDF" || uploadedDocument.type === "CSV" ? 
          <DocumentIcon /> : <ImageIcon />}
        </div>
        <div>
          <p className="main-text">{uploadedDocument.name}</p>
          <p className="desc-text">
            <span>{uploadedDocument.type}</span>
            <span className="dot"></span>{" "}
            <span>{(uploadedDocument.size / 1024).toFixed(2)} KB</span>
          </p>
        </div>
      </div>
      <div>
        <OutlineButton
          onClick={() => removeFile(index)}
          label={"Remove"}
          className="font-medium px-3.5 !py-1.5 text-nowrap !text-sm"
        />
      </div>
    </div>
  );
};

export default FileInfo;
