import { chargeDataPayloadType, chargeDataType } from "../components/PayoutCreationDrawer/types";

export const formatDataOptions = (data: any) => {
  return data.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
};

export function isNonNegativeNumber(value: any) {
  return typeof value === "number" && !isNaN(value) && value >= 0;
}

export const determineFileType = (url: string) => {
  const extension = url.split(".").pop();
  if (extension === "jpg" || extension === "jpeg" || extension === "png") {
    return "image";
  } else if (extension === "pdf") {
    return "pdf";
  }
  // Add more file types as needed
  return "unknown";
};

export const formatCurrency = (amount = 0) => {
  // Create Intl.NumberFormat object with Nigerian currency format
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  });

  // Format the currency
  return formatter.format(amount);
};

export const getNotificationLabel = (types: any[], id: number) => {
  return types.find((item) => item.id === id)?.name;
};

export const formatVendorDataOptions = (data: any) => {
  return data?.map((item: any) => {
    return {
      label: item.name,
      value: item.isoCode,
    };
  });
};

export const formatVendorBanks = (data: any) => {
  return data?.map((item: any) => {
    return {
      id: item.id,
      label: item.name,
      value: item.code,
    };
  });
};

export const getNameOfAudit = (items: Array<any>, id: number) => {
  const auditActionName = items.find((item) => item.id === id);
  return auditActionName?.name;
};

// export const getNameOfPayee = (items: Array<any>, id: number) => {
//   const payeeTypeName = items.find((item) => item.id === id);
//   return payeeTypeName.name;
// };

export const totalTaxInfo = (taxCharge: chargeDataType[]) => {
  if (taxCharge.length === 0) {
    return { totalPercentage: 0, totalCalculatedCharge: 0 };
  }

  const totalPercentage = taxCharge.reduce(
    (acc, charge) => acc + parseFloat(charge.ChargePercentage),
    0
  );
  const totalCalculatedCharge = taxCharge.reduce(
    (acc, charge) => acc + charge.CalculatedCharge,
    0
  );

  return { totalPercentage, totalCalculatedCharge };
};

export const capitalizeChargeKeys = (
  charges: string | chargeDataPayloadType[] | null
): chargeDataType[] | [] => {
  if (typeof charges === "string") {
    try {
      return JSON.parse(charges);
    } catch {
      return []; 
    }
  }

  if (!charges || !Array.isArray(charges)) {
    return []; 
  }

  return charges.map((charge) => ({
    ChargeType: charge.chargeType,
    ChargePercentage: charge.chargePercentage,
    CalculatedCharge: charge.calculatedCharge,
  }));
};