import { devInstance } from "../utils/axios/axiosInstance";

export const downloadBulkPayoutTemplate = async (payload: any) => {
  const response = await devInstance.get("/Payout/SampleTemplate", {
    params: payload,
  });
  return response.data;
};

export const bulkPayoutFileUpload = async (payload: any) => {
  const response = await devInstance.post(
    `/Payout`,
    payload
  );
  return response.data;
};

export const validateBulkPayeeDetail = async (payload: any) => {
  const response = await devInstance.post(
    `/Payout/ValidateUpload?payeeType=${payload?.payeeType}`,
    payload?.payeeDetails
  );
  return response.data;
};

export const initateBulkPayout = async (payload: any) => {
  const response = await devInstance.post(
    `/Payout/InitiateBulkPayout`,
    payload
  );
  return response.data;
};
