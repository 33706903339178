import { landingInstance } from "../utils/axios/landingInstance";

export const login = async (payload: any) => {
  const response = await landingInstance.post("/Account/Login", payload);
  return response;
};

export const forgotPassword = async (payload: any) => {
  const response = await landingInstance.get(
    `/Account/ForgotPassword?email=${payload.email}`
  );
  return response;
};

export const resetPassword = async (payload: any) => {
  const response = await landingInstance.post(
    "/Account/ResetPassword",
    payload
  );
  return response;
};

export const confirmAccount = async (payloay: {
  userToken: string;
  password: string;
  confirmPassword: string;
}) => {
  try {
    const response = await landingInstance.post("/Account/Confirm", payloay);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
};
