import { FC, useEffect, useState } from "react";
import Select from "react-select";
import { SelectPicker } from "rsuite";
import "./CustomDropdown.scss";

const CustomDropDown: FC<CustomDropdownProps> = ({
  dataItems,
  label,
  dropdownLabel,
  isSearchable = false,
  isClearable = false,
  setSelectedOption,
  selectedOption,
  className,
}) => {
  useEffect(() => {
    function hideError(e: any) {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    }

    window.addEventListener("error", hideError);
    return () => {
      window.addEventListener("error", hideError);
    };
  }, []);
  //   const [selectedOption, setSelectedOption] = useState<{
  //     label: string;
  //     value: string;
  //   }>({ label: "", value: "" });
  const [customValue, setCustomValue] = useState<string>("");

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    setCustomValue(inputValue);
    return inputValue;
  };

  const handleChange = (item: any) => {
    // Handle selected option
  };

  const handleOnSelect = (value: any, item: any, event: any) => {
    setSelectedOption(item);
  };

  return (
    <div>
      {label && (
        <label className="label" htmlFor={label}>
          {label}
        </label>
      )}

      <SelectPicker
        label={dropdownLabel}
        data={dataItems}
        appearance="subtle"
        placeholder={selectedOption.label || "All"}
        searchable={isSearchable}
        cleanable={isClearable}
        className={className}
        onChange={handleChange}
        onSearch={handleInputChange}
        onSelect={handleOnSelect}
      />
    </div>
  );
};

export default CustomDropDown;
