import { Modal } from "antd";
import PrimaryButton from "../Button/PrimaryButton";
import { useState } from "react";
import OutlineButton from "../OutlineButton/OutlineButton";
import { ReactComponent as DownloadIcon } from "../../assets/icons/downloadicon.svg";
import { ReactComponent as LeftAngleArrow } from "../../assets/icons/leftanglearrow.svg";
import { ReactComponent as RightAngleArrow } from "../../assets/icons/rightanglearrow.svg";
import { ReactComponent as PDFIcon } from "../../assets/icons/pdfIcon.svg";
import { downloadFile } from "../../utils/downloadFile";
import { toast } from "react-toastify";

interface SupportDocumentModalType {
  showSupportingDocumentModal: boolean;
  setShowSupportingDocumentModal: (
    showSupportingDocumentModal: boolean
  ) => void;
  documentLink: string;
}

const SupportDocumentModal = ({
  showSupportingDocumentModal,
  setShowSupportingDocumentModal,
  documentLink,
}: SupportDocumentModalType) => {
  const [isDocsLoading, setIsDocLoading] = useState(true);
  const [visibleFileIndex, setVisibileFileIndex] = useState(0);
  const [fileReadingError, setFileReadingError] = useState(false);

  // Split the documentLink string into an array of links
  const documentLinks = documentLink.split(",") || "";

  const handleFileReadingError = () => {
    setFileReadingError(true);
  };

  const downloadDocument = () => {
    const url = documentLinks[visibleFileIndex];
    downloadFile(url, `Supporting Document ${visibleFileIndex +1}`);
    toast?.success("Downloaded successfully");
  };

  const closeDownloadModal = () => {
    setVisibileFileIndex(0);
    setShowSupportingDocumentModal(false);
    setFileReadingError(false);
  };

  return (
    <Modal
      title={"Supporting documents"}
      open={showSupportingDocumentModal}
      onCancel={() => closeDownloadModal()}
      onOk={() => setShowSupportingDocumentModal(false)}
      footer={null}>
      <>
        <p className="label !text-sm mt-5 mb-2.5">
          {visibleFileIndex + 1} of {documentLinks?.length}
        </p>

        <div className="bg-[#FAFAFA] rounded-2xl p-4 flex gap-4 w-full items-center justify-between">
          <OutlineButton
            onClick={() => {
              setVisibileFileIndex((prev) => prev - 1);
              setFileReadingError(false);
            }}
            icon={<LeftAngleArrow />}
            className={`font-medium !py-1.5 !px-2 text-nowrap !text-sm ${
              visibleFileIndex === 0 && " invisible cursor-pointer"
            }`}
          />
          <div className="aspect-square h-full w-full flex items-center justify-center relative">
            {documentLinks?.map(
              (link, index) =>
                index === visibleFileIndex &&
                (!fileReadingError ? (
                  <img
                    src={link}
                    key={index}
                    alt="Document"
                    style={{ maxWidth: "100%" }}
                    className="z-50 aspect-square object-contain h-fit w-fit"
                    onLoad={() => setIsDocLoading(true)}
                    onError={() => {
                      setIsDocLoading(false);
                      handleFileReadingError();
                    }}
                  />
                ) : (
                  <div>
                    <PDFIcon />
                    <p className="label text-center !font-medium !text-sm">
                      This is a pdf file. Download to view
                    </p>
                  </div>
                ))
            )}
            {isDocsLoading && (
              <p
                className={`label text-center !font-medium !text-sm w-fit h-fit absolute top-0 bottom-0 left-0 right-0 m-auto `}>
                Loading, please wait...
              </p>
            )}
          </div>
          <OutlineButton
            onClick={() => {
              setVisibileFileIndex((prev) => prev + 1);
              setFileReadingError(false);
            }}
            icon={<RightAngleArrow />}
            className={`font-medium !py-1.5 !px-2 text-nowrap !text-sm ${
              visibleFileIndex === documentLinks.length - 1 &&
              " invisible cursor-pointer"
            }`}
          />
        </div>
        <div className="flex justify-end gap-3 items-center pt-4 mt-10">
          <OutlineButton
            onClick={() => {
              closeDownloadModal();
            }}
            label={"Close"}
            className="font-medium !py-1.5 text-nowrap !text-sm "
          />
          <PrimaryButton
            label={"Download"}
            icon={<DownloadIcon />}
            disabled={!documentLinks}
            onClick={() => downloadDocument()}
            className="!text-sm !w-fit !py-1.5 !px-4"
          />
        </div>
      </>
    </Modal>
  );
};

export default SupportDocumentModal;
